import api from '@/api/api';

const URLS = {
  tagsUrl: 'tags',
  tagsUrlTagCode: 'tags/{tagCode}',
  tagUrlTagCodeAttribute: 'tags/{tagCode}/attribute',
};

export const getTags = ({
  searchText,
  orderBy,
  order,
  maxItemsInResult,
  skipItemsInResult,
  tagType,
} = {}) => {
  return api.get(URLS.tagsUrl, {
    params: {
      searchText: searchText,
      orderBy: orderBy,
      order: order,
      maxItemsInResult: maxItemsInResult,
      skipItemsInResult: skipItemsInResult,
      tagType: tagType,
    },
  });
};

export const getTag = (tagCode, { includeUsage } = {}) => {
  return api.get(URLS.tagsUrlTagCode.replace('{tagCode}', tagCode), {
    params: {
      includeUsage: includeUsage,
    },
  });
};

export const createTag = ({ name, tagAttribute, tagType } = {}) => {
  return api.post(URLS.tagsUrl, {
    name: name,
    tagAttribute: tagAttribute,
    tagType: tagType,
  });
};

export const modifyTagAttribute = (tagCode, tagAttribute) => {
  return api.put(
    URLS.tagUrlTagCodeAttribute.replace('{tagCode}', tagCode),
    tagAttribute,
  );
};

export const updateTag = (tagCode, tagDetails) => {
  return api.put(URLS.tagsUrlTagCode.replace('{tagCode}', tagCode), tagDetails);
};

export const deleteTag = (tagCode) => {
  return api.delete(URLS.tagsUrlTagCode.replace('{tagCode}', tagCode));
};
