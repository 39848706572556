<template>
  <div class="va-timepicker">
    <div class="input-fields">
      <select
        :value="hour"
        class="input-field"
        data-testid="timepicker-select-hour"
        @change="changeHour($event.target.value)"
      >
        <option value="">--</option>
        <template v-if="has12HourClock">
          <option
            v-for="n in render12HourNumbers()"
            :key="n"
            :value="n.toString()"
          >
            {{ n.toString() }}
          </option>
        </template>
        <template v-else>
          <option value="00">00</option>
          <option
            v-for="n in 23"
            :key="n"
            :value="n.toString().padStart(2, '0')"
          >
            {{ n.toString().padStart(2, '0') }}
          </option>
        </template>
      </select>
      <div>:</div>
      <select
        :value="minute"
        class="input-field"
        data-testid="timepicker-select-minute"
        @change="changeMinute($event.target.value)"
      >
        <option value="">--</option>
        <option value="00">00</option>
        <option v-for="n in 59" :key="n" :value="n.toString().padStart(2, '0')">
          {{ n.toString().padStart(2, '0') }}
        </option>
      </select>
      <select
        v-if="has12HourClock"
        :value="period"
        class="input-field"
        data-testid="timepicker-select-am-pm"
        @change="changePeriod($event.target.value)"
      >
        <option value="am">AM</option>
        <option value="pm">PM</option>
      </select>
    </div>
  </div>
</template>

<script>
import { dateToLocaleString } from '@/helpers/localization';

export default {
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  computed: {
    hour() {
      const hour = this.modelValue?.slice(0, 5).split(':')[0] || '';
      if (this.has12HourClock && hour) {
        return hour === '00'
          ? 12
          : parseInt(hour) > 12
            ? parseInt(hour) - 12
            : parseInt(hour);
      }
      return hour.toString();
    },
    minute() {
      return this.modelValue?.slice(0, 5).split(':')[1] || '';
    },
    period() {
      if (this.has12HourClock) {
        const hour = this.modelValue?.slice(0, 5).split(':')[0] || '';
        return hour < 12 ? 'am' : 'pm';
      }
      return '';
    },
    has12HourClock() {
      return this.check12HourClock();
    },
  },
  methods: {
    changeHour(hour) {
      const minute = hour ? this.minute || '00' : '';
      if (this.has12HourClock) {
        hour = this.convertTo24HourFormat(this.period, hour);
      }
      this.emitTime(hour, minute);
    },
    changeMinute(minute) {
      let hour = minute ? this.hour || '00' : '';
      if (this.has12HourClock) {
        hour = this.convertTo24HourFormat(this.period, hour);
      }
      this.emitTime(hour, minute);
    },
    changePeriod(period) {
      let hour = this.hour;
      if (this.has12HourClock) {
        hour = this.convertTo24HourFormat(period, hour);
      }
      this.emitTime(hour, this.minute);
    },
    emitTime(hour, minute) {
      const time = hour && minute ? `${hour}:${minute}` : null;
      this.$emit('update:modelValue', time);
    },
    check12HourClock() {
      const date = new Date();
      return (
        dateToLocaleString(date.toString()).toUpperCase().endsWith('AM') ||
        dateToLocaleString(date.toString()).toUpperCase().endsWith('PM')
      );
    },
    convertTo24HourFormat(period, hour) {
      if (!hour) {
        return '';
      }
      let h =
        period === 'pm' && parseInt(hour) != 12
          ? parseInt(hour) + 12
          : parseInt(hour);
      if (parseInt(hour) === 12) {
        if (period === 'pm') {
          h = 12;
        } else {
          h = 0;
        }
      }
      return h.toString().padStart(2, '0');
    },
    render12HourNumbers() {
      return [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    },
  },
};
</script>

<style lang="scss" scoped>
.va-timepicker {
  height: 40px;
  position: relative;
  display: inline-flex;
  background-color: $color-input;
  border: 1px solid $color-input-border;
  border-radius: 0.25rem;

  &:hover {
    border-color: $color-input-border-hover;
  }
}

.input-fields {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 40px;
  font-family: inherit;
  font-size: 14px;
  line-height: 2.86;
  color: $color-text;
  background-color: inherit;

  &::placeholder {
    color: $color-text-secondary;
  }

  .input-field {
    height: 100%;
    background-color: inherit;
    border: none;
    border-radius: 0.25rem;
    text-align: center;
    font-family: inherit;
    font-size: 14px;
    line-height: 2.86;
    color: $color-text;
    outline: none;

    &:focus-visible {
      outline: $color-border-focus solid 1px;
    }
  }
}
</style>
