<template>
  <div class="va-media-picker">
    <va-modal
      :type="'confirm'"
      :width="1100"
      @confirm="onConfirmClick"
      @close="$emit('close')"
    >
      <template #header>
        <h3>
          {{ $translate('mediaPicker.header') }}
        </h3>
      </template>
      <template #body>
        <media-nav-bar
          :view-menu="true"
          :tab-bar-items-settings="tabBarItemsSettings"
          @search="onSearch"
          @tab-click="onTabClick"
        />
        <div v-if="activeViewLayout === GRID_VIEW" class="media-list-wrapper">
          <div class="media-grid">
            <div
              v-for="media in items"
              :key="media.mediaCode"
              class="media-item"
              :class="{
                selected: selectedMedia.find((m) => {
                  return m.mediaCode === media.mediaCode;
                }),
              }"
              @click="selectMedia(media)"
            >
              <div class="media-thumbnail-wrapper">
                <va-img
                  :src="media.thumbnailUrl"
                  class="media-thumbnail"
                  :media-type="media.mediaType"
                  :value="media"
                />
                <div class="media-orientations">
                  <img
                    v-if="~media.orientations.indexOf('Landscape')"
                    src="@/assets/images/landscape.svg"
                  />
                  <img
                    v-if="~media.orientations.indexOf('Portrait')"
                    src="@/assets/images/portrait.svg"
                  />
                </div>
              </div>
              <div class="media-details">
                <span class="media-name" :title="media.displayName">
                  {{ media.displayName }}
                </span>
              </div>
            </div>
          </div>
          <div v-if="!loadingItems && items.length === 0">
            <p v-if="!searchText" class="no-items">
              {{ $translate('generic.noResultFound') }}
            </p>
            <p v-else class="no-items">
              {{ $translate('generic.noResultsFoundFor', searchText) }}
            </p>
          </div>
          <div v-else class="grid-show-more-button__container">
            <va-button
              color="secondary"
              :disabled="allItemsLoaded"
              :loading="loadingItems"
              class="show-more-button"
              @click="loadMoreItems"
            >
              {{ loadMoreButtonText }}
            </va-button>
          </div>
        </div>
        <div v-else class="media-list-wrapper list">
          <va-list
            :columns="getColumns"
            :items="items"
            :selected-items="selectedMedia.map((m) => m.mediaCode)"
            :load-more-button-label="loadMoreButtonText"
            :all-items-loaded="allItemsLoaded"
            :loading-items="loadingItems"
            :order="order"
            :order-by="orderBy"
            test-id="displayName"
            item-id="mediaCode"
            class="va-list-media"
            @LOAD_MORE_ITEMS="loadMoreItems"
            @ORDER_BY="onSort"
            @update:selected-items="onSelectedChange"
          >
            <template #cell-mediaName="{ item: media }">
              <div class="media-name">
                <va-img
                  :src="media.thumbnailUrl"
                  class="va-list-thumbnail"
                  :media-type="media.mediaType"
                  :value="media"
                  @click="onPreviewItem(media)"
                />
                <span class="media-name--name">{{ media.displayName }}</span>
              </div>
            </template>
            <template #cell-tags="{ item: media }">
              <div class="media-tags">
                {{ media.tags.join(', ') }}
              </div>
            </template>
            <template #cell-layout="{ item: media }">
              <div class="media-layout">
                {{
                  media.orientations
                    .map((o) => getOrientationByLang(o))
                    .join(', ')
                }}
              </div>
            </template>
            <template #cell-template="{ item: media }">
              <div class="media-template">
                {{
                  media.mediaSpecificInformation
                    ? media.mediaSpecificInformation.templateName
                    : ''
                }}
              </div>
            </template>
            <template #empty-state>
              <div v-if="!loadingItems && items.length === 0">
                <p v-if="!searchText" class="no-items">
                  {{ $translate('generic.noResultFound') }}
                </p>
                <p v-else class="no-items">
                  {{ $translate('generic.noResultsFoundFor', searchText) }}
                </p>
              </div>
            </template>
          </va-list>
        </div>
      </template>
      <template #footer>
        <footer class="footer-buttons">
          <va-button color="secondary" @click="$emit('close')">
            {{ $translate('generic.cancel') }}
          </va-button>
          <va-button
            data-testid="media-picker-modal--confirm-button"
            @click="onConfirmClick"
          >
            {{ $translate('generic.add') }}
          </va-button>
        </footer>
      </template>
    </va-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VaModal from '@/components/framework/va-modal.vue';
import VaList from '@/components/framework/va-list/va-list.vue';
import VaImg from '@/components/framework/va-img.vue';
import VaButton from '@/components/framework/VaButton.vue';
import MediaNavBar from '@/views/media/components/MediaNavBar.vue';
import { search } from '@/mixins/search';
import { getMedia } from '@/api/mediaApi';
import { CommonConstants } from '@/constants/constants.js';
import { getMediaFilter } from '@/api/mediaFilterApi';
import { withAsync } from '@/helpers/withAsync';

export default {
  components: {
    VaModal,
    VaImg,
    VaButton,
    VaList,
    MediaNavBar,
  },
  mixins: [search],
  props: {
    singleSelect: {
      type: Boolean,
    },
    library: {
      type: String,
      default: '',
    },
  },
  emits: ['close', 'selectedMedia'],
  data() {
    return {
      searchFunction: getMedia,
      order: 'desc',
      orderBy: 'RegisteredDateTime',
      itemsToGet: 'Medias',
      selectedMedia: [],
      mediaTypes: this.library === 'BookingMedia' ? ['Photo', 'Video'] : [],
    };
  },
  computed: {
    ...mapGetters({
      activeViewLayout: 'activeViewLayout',
    }),
    items() {
      return this.searchResult || [];
    },
    searchParameters() {
      if (this.library) {
        return {
          libraries: [this.library],
          mediaTypes: this.mediaTypes,
        };
      }
      return { mediaTypes: this.mediaTypes };
    },
    getColumns() {
      const columns = [
        {
          id: 'checkbox',
          name: '',
          enabled: true,
          locked: true,
          checkbox: true,
        },
        {
          id: 'mediaName',
          name: this.$translate('media.listHeadingLabels.name'),
          enabled: true,
          orderBy: 'MediaName',
        },
        {
          id: 'uploaded',
          name: this.$translate('media.listHeadingLabels.uploaded'),
          attributeName: 'registeredDateTime',
          enabled: true,
          orderBy: 'RegisteredDateTime',
        },
        {
          id: 'tags',
          name: this.$translate('media.listHeadingLabels.tags'),
          enabled: true,
        },
        {
          id: 'layout',
          name: this.$translate('media.listHeadingLabels.layout'),
          enabled: true,
          orderBy: 'Layout',
        },
      ];

      return columns;
    },
    loadMoreButtonText() {
      return this.allItemsLoaded
        ? this.$translate('media.allMediaLoaded')
        : this.$translate('media.loadMoreMedia');
    },
    tabBarItemsSettings() {
      if (this.library === 'ItemImages') {
        return {};
      } else if (this.library === 'BookingMedia') {
        return { hasImages: true, hasVideos: true };
      }
      return { hasImages: true, hasVideos: true, hasSigns: true };
    },
  },
  created() {
    this.GRID_VIEW = CommonConstants.GRID_VIEW;
    this.LIST_VIEW = CommonConstants.LIST_VIEW;
    this.getItems();
  },
  methods: {
    onConfirmClick() {
      this.$emit('selectedMedia', this.selectedMedia);
    },
    selectMedia(media) {
      if (
        this.selectedMedia.find((m) => {
          return m.mediaCode === media.mediaCode;
        })
      ) {
        this.selectedMedia.splice(this.selectedMedia.indexOf(media), 1);
      } else if (this.singleSelect) {
        this.selectedMedia = [media];
      } else {
        this.selectedMedia.push(media);
      }
    },
    getOrientationByLang(orientation) {
      switch (orientation) {
        case 'Landscape':
          return this.$translate('generic.orientations.landscape');
        case 'Portrait':
          return this.$translate('generic.orientations.portrait');
        case 'Ultrawide':
          return this.$translate('generic.orientations.ultrawide');
        default:
          return orientation;
      }
    },
    onSelectedChange(items) {
      this.selectedMedia = this.items.filter((media) => {
        return items.includes(media.mediaCode);
      });
    },
    async getMediaFilter(filterCode) {
      const { response } = await withAsync(getMediaFilter, filterCode);
      this.condition = response?.data.condition;
      this.getItems();
    },
    onTabClick(action) {
      if (action.startsWith('mediaFilter:')) {
        const filterCode = action.split(':')[1];
        this.getMediaFilter(filterCode);
      } else {
        this.condition = null;
        switch (action) {
          case 'images':
            this.mediaTypes = ['Photo'];
            break;
          case 'videos':
            this.mediaTypes = ['Video'];
            break;
          case 'signs':
            this.mediaTypes = ['HtmlAd', 'WebPage'];
            break;
          default:
            this.mediaTypes =
              this.library === 'BookingMedia' ? ['Photo', 'Video'] : [];
        }
        this.getItems();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.va-media-picker {
  :deep(.va-breadcrumbs) {
    display: none;
  }

  .media-list-wrapper {
    overflow: hidden scroll;
    height: 50vh;

    &.list {
      overflow: hidden;
    }
  }

  .va-list-media {
    .media-name {
      display: flex;
      align-items: center;

      &--name {
        overflow-wrap: anywhere;
      }
    }
  }

  .media-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .media-item {
      margin: 15px;
      width: 171px;
      border: 2px solid transparent;
      cursor: pointer;

      &.selected {
        border: 2px solid $color-fill-active;
      }

      .media-thumbnail-wrapper {
        height: 171px;
        position: relative;
      }

      .media-thumbnail {
        width: 171px;
        height: 171px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: $color-bg-secondary;
      }

      .media-orientations {
        position: absolute;
        padding: 10px;
        top: 0;
        right: 0;
        display: flex;

        img {
          margin-left: 10px;
        }
      }

      .media-details {
        height: 30px;
        background-color: $color-bg-secondary;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        font-size: 0.75rem;
        line-height: 15px;

        .media-name {
          width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  p.no-items {
    padding: 1rem;
    display: flex;
    justify-self: center;
    color: $color-text;
  }

  .grid-show-more-button {
    &__container {
      display: flex;
      justify-content: center;
      padding-bottom: 1rem;
    }
  }
}
</style>
