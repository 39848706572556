import api from '@/api/api';

const URLS = {
  triggers: 'triggers',
  triggersWithTriggerCode: 'triggers/{triggerCode}',
  accountUrl: 'triggers/account',
  locations: 'triggers/locations',
  locationsWithTriggerCode: 'triggers/locations/{triggerCode}',
  locationRestoreUrl: 'triggers/locations/restore/{triggerCode}',
  locationsRestoreRemoveUrl: 'triggers/locations/remove/{triggerCode}',
  players: 'triggers/players',
  playersWithLocationCodePlayerCode:
    'triggers/players/{locationCode}/{triggerCode}',
  playersRestoreUrl: 'triggers/players/restore/{triggerCode}',
  playersRestoreRemoveUrl: 'triggers/players/remove/{triggerCode}',
};

export const getAccountTriggers = (
  {
    triggerGroup,
    searchText,
    order,
    orderBy,
    maxItemsInResult,
    skipItemsInResult,
  },
  config,
) => {
  return api.post(
    URLS.accountUrl,
    {
      triggerGroup: triggerGroup,
      searchText: searchText,
      order: order,
      orderBy: orderBy,
      maxItemsInResult: maxItemsInResult,
      skipItemsInResult: skipItemsInResult,
    },
    config,
  );
};

export const getLocationTriggers = (
  triggerCode,
  {
    triggerGroup,
    searchText,
    order,
    orderBy,
    maxItemsInResult,
    skipItemsInResult,
  },
  config,
) => {
  return api.post(
    URLS.locationsWithTriggerCode.replace('{triggerCode}', triggerCode),
    {
      triggerGroup: triggerGroup,
      searchText: searchText,
      order: order,
      orderBy: orderBy,
      maxItemsInResult: maxItemsInResult,
      skipItemsInResult: skipItemsInResult,
    },
    config,
  );
};

export const getPlayerTriggers = (
  locationCode,
  triggerCode,
  {
    triggerGroup,
    searchText,
    order,
    orderBy,
    maxItemsInResult,
    skipItemsInResult,
  },
  config,
) => {
  return api.post(
    URLS.playersWithLocationCodePlayerCode
      .replace('{locationCode}', locationCode)
      .replace('{triggerCode}', triggerCode),
    {
      triggerGroup: triggerGroup,
      searchText: searchText,
      order: order,
      orderBy: orderBy,
      maxItemsInResult: maxItemsInResult,
      skipItemsInResult: skipItemsInResult,
    },
    config,
  );
};

export const deleteTrigger = (triggerCode) => {
  return api.delete(
    URLS.triggersWithTriggerCode.replace('{triggerCode}', triggerCode),
  );
};

export const restoreTriggerForLocations = (triggerCode, locationCode) => {
  return api.put(
    URLS.locationRestoreUrl.replace('{triggerCode}', triggerCode),
    {
      locationCodes: locationCode,
    },
  );
};

export const restoreTriggerForPlayers = (triggerCode, playerCodes) => {
  return api.put(URLS.playersRestoreUrl.replace('{triggerCode}', triggerCode), {
    playerCodes: playerCodes,
  });
};

export const removeTriggerForLocation = (triggerCode, locationCodes) => {
  return api.put(
    URLS.locationsRestoreRemoveUrl.replace('{triggerCode}', triggerCode),
    { locationCodes: locationCodes },
  );
};

export const removeTriggerForPlayer = (triggerCode, playerCodes) => {
  return api.put(
    URLS.playersRestoreRemoveUrl.replace('{triggerCode}', triggerCode),
    { playerCodes: playerCodes },
  );
};

export const getTrigger = (triggerCode) => {
  return api.get(
    URLS.triggersWithTriggerCode.replace('{triggerCode}', triggerCode),
  );
};

export const updateTrigger = ({
  triggerCode,
  name,
  triggerGroup,
  actionType,
  groupDetails,
}) => {
  return api.put(URLS.triggers, {
    triggerGroup: triggerGroup,
    name: name,
    triggerCode: triggerCode,
    actionType: actionType,
    ...groupDetails,
  });
};

export const createTrigger = ({
  name,
  triggerGroup,
  actionType,
  groupDetails,
}) => {
  return api.post(URLS.triggers, {
    triggerGroup: triggerGroup,
    name: name,
    actionType: actionType,
    ...groupDetails,
  });
};

export const updateTriggerForLocations = ({
  locationCodes,
  trigger,
  groupDetails,
}) => {
  return api.put(URLS.locations, {
    locationCodes: locationCodes,
    trigger: { ...trigger, ...groupDetails },
  });
};

export const updateTriggerForPlayers = ({
  playerCodes,
  trigger,
  groupDetails,
}) => {
  return api.put(URLS.players, {
    playerCodes: playerCodes,
    trigger: { ...trigger, ...groupDetails },
  });
};
