<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 4C8.41421 4 8.75 4.33579 8.75 4.75V5H11.25V4.75C11.25 4.33579 11.5858 4 12 4C12.4142 4 12.75 4.33579 12.75 4.75V5H13.5C14.4665 5 15.25 5.7835 15.25 6.75V13.75C15.25 14.7165 14.4665 15.5 13.5 15.5H6.5C5.5335 15.5 4.75 14.7165 4.75 13.75V6.75C4.75 5.7835 5.5335 5 6.5 5H7.25V4.75C7.25 4.33579 7.58579 4 8 4ZM7.25 6.5H6.5C6.36193 6.5 6.25 6.61193 6.25 6.75V8H13.75V6.75C13.75 6.61193 13.6381 6.5 13.5 6.5H12.75V6.75C12.75 7.16421 12.4142 7.5 12 7.5C11.5858 7.5 11.25 7.16421 11.25 6.75V6.5H8.75V6.75C8.75 7.16421 8.41421 7.5 8 7.5C7.58579 7.5 7.25 7.16421 7.25 6.75V6.5ZM13.75 9.5H6.25V13.75C6.25 13.8881 6.36193 14 6.5 14H13.5C13.6381 14 13.75 13.8881 13.75 13.75V9.5Z"
      fill="#181E24"
    />
  </svg>
</template>
