<template>
  <div class="va-toggle-container" @click="onToggleClick">
    <div v-if="label" class="va-toggle-label" :class="{ disabled: isDisabled }">
      {{ label }}
    </div>
    <div class="va-toggle" :class="{ on: toggle, disabled: isDisabled }">
      <div class="toggle-animation" :class="{ on: toggle }" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      toggle: false,
    };
  },
  watch: {
    modelValue(newVal) {
      this.toggle = newVal;
    },
  },
  created() {
    this.toggle = this.modelValue;
  },
  methods: {
    onToggleClick() {
      if (!this.isDisabled) {
        this.toggle = !this.toggle;
        this.$emit('update:modelValue', this.toggle);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.va-toggle-container {
  user-select: none;
  position: relative;
  display: inline-flex;
  cursor: default;

  .va-toggle-label {
    display: inline-block;
    line-height: 24px;
    height: 24px;
    text-align: center;
    margin-right: 10px;
    cursor: pointer;
  }

  .va-toggle {
    display: inline-block;
    width: 45px;
    height: 20px;
    background-color: $color-fill-inactive;
    border-radius: 19.5px;
    padding: 2px;
    position: relative;
    cursor: pointer;
    box-sizing: content-box;
    transition: 0.2s;

    &.on {
      background-color: $color-fill-active;
    }

    .toggle-animation {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 2px;
      left: 2px;
      border-radius: 19.5px;
      background-color: $color-fill-secondary;
      transition: 0.2s;

      &.on {
        top: 2px;
        left: 26px;
        background-color: $color-fill;
      }
    }
  }
}
</style>
