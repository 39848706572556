<script setup lang="ts" generic="T extends boolean | string[]">
import { computed } from 'vue';

const {
  label = '',
  disabled = false,
  modelValue = false,
  value = '',
} = defineProps<{
  label?: string;
  disabled?: boolean;
  modelValue?: T;
  value?: string;
  indeterminate?: boolean;
}>();

const isChecked = computed(() => {
  if (modelValue instanceof Array) {
    return modelValue.includes(value);
  }
  return modelValue;
});

const emit = defineEmits<{
  'update:modelValue': [value: T];
}>();

const onChange = (event: Event) => {
  if (modelValue instanceof Array) {
    const newValue = [...modelValue];

    if ((event.target as HTMLInputElement)?.checked) {
      newValue.push(value);
    } else {
      newValue.splice(newValue.indexOf(value), 1);
    }

    emit('update:modelValue', newValue as T);
  } else {
    emit('update:modelValue', (event.target as HTMLInputElement)?.checked as T);
  }
};
</script>

<template>
  <label :class="{ disabled }">
    <input
      :data-testid="`checkbox-checkmark-${value ? value : isChecked}`"
      :value="value"
      :checked="isChecked"
      type="checkbox"
      @change="onChange"
    />
    <svg
      v-if="isChecked && indeterminate"
      class="indeterminate"
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="2"
      viewBox="0 0 8 2"
      fill="none"
    >
      <path
        d="M1 1H7"
        stroke="#FAFAFA"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
    <svg
      v-else-if="isChecked"
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
    >
      <path
        d="M1 4.75L4 8.25L9 1.75"
        stroke="#FAFAFA"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <span v-if="label">{{ label }}</span>
  </label>
</template>

<style scoped lang="scss">
label {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: 0.875rem;
  gap: 0.5rem;
  cursor: pointer;
  user-select: none;
}

input[type='checkbox'] {
  appearance: none;
  width: 1rem;
  height: 1rem;
  background-color: $color-fill-inactive;
  border: 1px solid $color-action-border;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  margin: 0;
  transition:
    background-color 0.2s,
    border-color 0.2s;

  &:hover {
    border-color: $color-action-border-hover;
  }
}

input[type='checkbox']:checked {
  background-color: $color-fill-active;
  border-color: $color-fill-active;
}

svg {
  position: absolute;
  top: 50%;
  left: 0.1875rem;
  transform: translateY(-50%);
  pointer-events: none;

  &.indeterminate {
    left: 0.25rem;
  }
}
</style>
