import api from '@/api/api';

const URLS = {
  factWithTypeAndCode: 'facts/{factType}/{factCode}',
  factWithType: 'facts/{factType}',
  factSchemas: 'facts/schemas',
  factsSearch: 'facts/search',
  factsRemove: 'facts/remove',
  factsFilters: 'facts/filters',
  getFactSchemeUrl: 'facts/scheme/{factType}/{viewType}',
  factFiltersWithFilterCode: 'facts/filters/{filterCode}',
  factsAttributeWithAttributeCode: 'facts/attributes/{attributeCode}',
  factsWithFactTypeFactCode: 'facts/{factType}/{factCode}',
  factNewWithFactType: 'facts/{factType}/new',
  factsModifyWithFactType: 'facts/{factType}/modifyattributes',
  factsFiltersSearchWithFactType: 'facts/filters/{factType}/search',
};

export const getFact = (factType, factCode) => {
  return api.get(
    URLS.factWithTypeAndCode
      .replace('{factType}', factType)
      .replace('{factCode}', factCode),
  );
};

export const searchFacts = (
  factType,
  {
    condition,
    searchText,
    orderBy,
    order,
    maxItemsInResult,
    skipItemsInResult,
  },
) => {
  return api.post(URLS.factsSearch, {
    FactType: factType,
    Condition: condition,
    SearchText: searchText,
    OrderBy: orderBy,
    Order: order,
    MaxItemsInResult: maxItemsInResult,
    SkipItemsInResult: skipItemsInResult,
  });
};
export const searchFactsByFactType = ({
  factType,
  condition,
  searchText,
  orderBy,
  order,
  maxItemsInResult,
  skipItemsInResult,
}) => {
  return api.post(URLS.factWithType.replace('{factType}', factType), {
    Condition: condition,
    SearchText: searchText,
    OrderBy: orderBy,
    Order: order,
    MaxItemsInResult: maxItemsInResult,
    SkipItemsInResult: skipItemsInResult,
  });
};

export const getFactScheme = (factType, viewType) => {
  return api.get(
    URLS.getFactSchemeUrl
      .replace('{factType}', factType)
      .replace('{viewType}', viewType),
  );
};

export const getFactsSchemas = () => {
  return api.get(URLS.factSchemas);
};

export const getFactFilter = (filterCode) => {
  return api.get(
    URLS.factFiltersWithFilterCode.replace('{filterCode}', filterCode),
  );
};

export const getFactFilters = ({
  factType,
  searchText,
  orderBy,
  order,
  maxItemsInResult,
  skipItemsInResult,
}) => {
  return api.get(
    URLS.factsFiltersSearchWithFactType.replace('{factType}', factType),
    {
      params: {
        SearchText: searchText,
        OrderBy: orderBy,
        Order: order,
        MaxItemsInResult: maxItemsInResult,
        SkipItemsInResult: skipItemsInResult,
      },
    },
  );
};

export const removeFacts = (factType, factCodes) => {
  return api.post(URLS.factsRemove, {
    FactType: factType,
    FactCodes: factCodes,
  });
};

export const searchFactsAttributesValues = ({
  attributeCode,
  searchText,
  maxItemsInResult,
  skipItemsInResult,
}) => {
  return api.post(
    URLS.factsAttributeWithAttributeCode.replace(
      '{attributeCode}',
      attributeCode,
    ),
    {
      SearchText: searchText,
      MaxItemsInResult: maxItemsInResult,
      SkipItemsInResult: skipItemsInResult,
    },
  );
};

export const updateFact = (factType, factCode, attributeValues) => {
  return api.put(
    URLS.factsWithFactTypeFactCode
      .replace('{factType}', factType)
      .replace('{factCode}', factCode),
    {
      AttributeValues: attributeValues,
    },
  );
};

export const updateFacts = ({
  factType,
  factCodes = [],
  attributeCode,
  remove,
  attributeValues,
}) => {
  return api.post(
    URLS.factsModifyWithFactType.replace('{factType}', factType),
    {
      factCodes: factCodes,
      attributeCode: attributeCode,
      remove: remove,
      attributeValues: attributeValues,
    },
  );
};

export const addFact = (factType, attributeValues) => {
  return api.post(URLS.factNewWithFactType.replace('{factType}', factType), {
    AttributeValues: attributeValues,
  });
};

export const editFactFilter = ({
  filterCode,
  filterName,
  condition,
  factType,
}) => {
  return api.put(
    URLS.factFiltersWithFilterCode.replace('{filterCode}', filterCode),
    {
      attributeFilterName: filterName,
      filter: { condition: condition },
      factType: factType,
    },
  );
};

export const saveFactFilter = ({ filterName, condition, factType }) => {
  return api.post(URLS.factsFilters, {
    attributeFilterName: filterName,
    condition: condition,
    factType: factType,
  });
};

export const deleteFactFilter = (filterCode) => {
  return api.delete(
    URLS.factFiltersWithFilterCode.replace('{filterCode}', filterCode),
  );
};
