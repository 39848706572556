<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
      fill="currentColor"
    />
    <path
      d="M6 11.0001C5.90538 11.0004 5.81261 10.9739 5.73247 10.9236C5.65233 10.8733 5.58811 10.8013 5.54727 10.7159C5.50643 10.6306 5.49064 10.5354 5.50175 10.4414C5.51285 10.3474 5.55039 10.2586 5.61 10.1851L10.56 5.08508C10.5971 5.04222 10.6477 5.01326 10.7035 5.00295C10.7592 4.99264 10.8169 5.00159 10.8669 5.02833C10.9169 5.05508 10.9563 5.09803 10.9787 5.15014C11.001 5.20224 11.005 5.26041 10.99 5.31508L10.03 8.32508C10.0017 8.40084 9.99218 8.48234 10.0023 8.56258C10.0124 8.64283 10.0418 8.71942 10.088 8.78579C10.1343 8.85217 10.1959 8.90634 10.2676 8.94366C10.3394 8.98098 10.4191 9.00034 10.5 9.00008H14C14.0946 8.99976 14.1874 9.02629 14.2675 9.07659C14.3477 9.12689 14.4119 9.1989 14.4527 9.28425C14.4936 9.3696 14.5093 9.46479 14.4982 9.55875C14.4871 9.65272 14.4496 9.7416 14.39 9.81508L9.44 14.9151C9.40286 14.9579 9.35227 14.9869 9.2965 14.9972C9.24074 15.0075 9.18313 14.9986 9.13313 14.9718C9.08313 14.9451 9.0437 14.9021 9.02133 14.85C8.99895 14.7979 8.99496 14.7398 9.01 14.6851L9.97 11.6751C9.9983 11.5993 10.0078 11.5178 9.9977 11.4376C9.98759 11.3573 9.95817 11.2807 9.91195 11.2144C9.86574 11.148 9.80411 11.0938 9.73236 11.0565C9.66061 11.0192 9.58087 10.9998 9.5 11.0001H6Z"
      fill="#181E24"
    />
  </svg>
</template>
