<template>
  <div class="va-field va-media">
    <label class="title-label">
      {{ getTranslatedLabel(fieldModel.label, $getUserlanguage()) }}
    </label>
    <va-file-upload
      v-if="!preview"
      @media-added-from-library="onMediaSelected"
      @media-upload-ok="onMediaSelected"
    >
      <SvgIcon icon="mediaLibrary" class="media-icon" />
      <h3 class="drop-text">
        {{ $translate('generic.dragAndDrop.header') }}
      </h3>
      <p class="drop-text">
        {{ $translate('generic.dragAndDrop.description') }}
      </p>
    </va-file-upload>
    <div v-if="preview" class="file-select dropzone">
      <div v-if="loading" class="loader" />
      <div class="file-select-header">
        <button
          class="file-select-preview-button"
          :title="$translate('media.module.preview')"
          @click="showMediaPreviewModal = !showMediaPreviewModal"
        >
          {{ activeItem.displayName
          }}<span v-if="type === 'HtmlAd'">.sign </span>
        </button>

        <div class="file-select-options">
          <button
            v-if="type === 'Photo'"
            class="file-select-edit file-select-edit-button"
            :title="$translate('media.module.editImage')"
            @click="showMediaPreview = true"
          >
            <SvgIcon icon="square-pen" class="file-select-option" />
          </button>
          <router-link
            v-else-if="type === 'HtmlAd'"
            target="_blank"
            :title="$translate('media.module.editSign')"
            :to="{
              name: 'TemplateFormEdit',
              params: {
                mediaCode: activeItem.mediaCode,
                datacode: 'edit',
                target: 'media',
              },
            }"
            class="file-select-edit file-select-edit-link"
          >
            <SvgIcon icon="square-pen" class="file-select-option" />
          </router-link>
          <button
            class="file-select-clear"
            :title="$translate('media.module.clear')"
            @click="selectedMediaClear"
          >
            <SvgIcon icon="trash2" class="file-select-option" />
          </button>
        </div>
      </div>
      <va-vid
        v-if="type === 'Video'"
        ref="mediaVideo"
        class="preview-image"
        :src="preview"
        @canplay="loading = false"
      />
      <va-img
        v-else
        ref="mediaImage"
        :src="preview"
        :media-type="type"
        @load="loading = false"
      />
    </div>
    <media-preview
      v-if="showMediaPreview"
      :item="activeItem"
      :edit-mode="true"
      @close="showMediaPreview = false"
      @addedMedia="onCropped($event)"
    />
    <media-preview-modal
      v-if="showMediaPreviewModal"
      :media-code="activeItem.mediaCode"
      :media-type="activeItem.mediaTypes"
      :display-name="activeItem.displayName"
      :preview-url="activeItem.previewUrl"
      @close="showMediaPreviewModal = false"
    />
  </div>
</template>

<script>
import VaImg from '@/components/framework/va-img.vue';
import VaVid from '@/components/framework/va-vid.vue';
import VaFileUpload from '@/components/framework/va-form/va-fileupload.vue';
import MediaPreview from '@/components/medialibrary/MediaPreview.vue';
import MediaPreviewModal from '@/components/medialibrary/MediaPreviewModal.vue';
import SvgIcon from '@/components/icons/SvgIcon.vue';
import { userComputed } from '@/services/auth.service';
import { getTranslatedLabel } from '@/helpers/form-helper';
import { withAsync } from '@/helpers/withAsync';
import { getMetadata } from '@/api/mediaApi';

export default {
  components: {
    MediaPreview,
    MediaPreviewModal,
    VaImg,
    VaVid,
    VaFileUpload,
    SvgIcon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    fieldModel: {
      type: Object,
      required: true,
    },
  },
  emits: ['updatedData'],
  data() {
    return {
      preview: '',
      type: '',
      loading: true,
      activeItem: null,
      showMediaPreview: false,
      showMediaPreviewModal: false,
    };
  },
  computed: {
    ...userComputed,
  },
  created() {
    this.onMediaSelected(this.data[this.fieldModel.fieldName].media);
  },
  methods: {
    getTranslatedLabel,
    onCropped(croppedImage) {
      this.onMediaSelected(croppedImage.mediaCode);
    },
    async onMediaSelected(mediaCode) {
      if (mediaCode) {
        const { response } = await withAsync(getMetadata, mediaCode);
        if (response) {
          this.activeItem = response.data;
          const item = this.activeItem;

          this.type = item.mediaTypes;
          this.preview =
            this.type === 'HtmlAd'
              ? item.thumbnailUrl.replace('thumbnail', 'preview')
              : item.previewUrl;
          // TODO Fix no-mutating-props issue
          // eslint-disable-next-line vue/no-mutating-props
          this.data[this.fieldModel.fieldName] = {
            mediaPreviewUrlWithToken:
              item.previewUrl + '?access_token=' + this.user?.access_token,
            media: mediaCode,
            type: this.type.toLowerCase(),
          };
          this.$emit('updatedData');
        }
      }
    },
    selectedMediaClear() {
      // TODO Fix no-mutating-props issue
      // eslint-disable-next-line vue/no-mutating-props
      this.data[this.fieldModel.fieldName] = '';
      this.preview = '';
      this.$emit('updatedData');
    },
  },
};
</script>

<style lang="scss">
html body .va-media {
  position: relative;

  .va-media-draggable .sortable-chosen {
    display: none;
  }

  .file-select {
    min-height: 12.5rem;
    position: relative;
    color: $color-text-secondary;

    .va-img-container {
      height: 170px;
      border-radius: 3px;
      background-color: $color-bg-secondary;

      .va-img {
        object-fit: contain;
      }
    }

    .va-vid-container {
      min-height: 12.5rem;

      .va-vid {
        object-fit: contain;
      }
    }

    .file-select-header {
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      left: 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .file-select-preview-button {
        word-break: break-all;
        background: $color-fill;
        color: $color-text-inverted;
        opacity: 0.6;
        cursor: pointer;
        margin: 0.7rem;
        padding: 0.3rem 0.6rem;
        border: none;
        border-radius: 1rem;
        transition: background ease-in-out 0.2s;
        text-align: left;

        &:hover {
          opacity: 1;
        }
      }

      .file-select-options {
        flex-shrink: 0;

        .file-select-clear,
        .file-select-play,
        .file-select-edit {
          cursor: pointer;
          padding: 0.7rem 0.4rem;
          border: none;
          background: transparent;
          color: inherit;

          .file-select-option {
            font-size: 1.4rem;
            margin: 0;
          }

          &:hover {
            .file-select-option {
              color: $color-fill;
            }
          }
        }
      }
    }
  }

  .drop-text {
    color: $color-text-secondary;
  }

  .media-icon {
    font-size: 2rem;
    padding-bottom: 1rem;
    color: $color-text-secondary;
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    border: 0.3rem solid $color-fill-inactive;
    border-top: 0.3rem solid $color-fill-active;
    border-radius: 50%;
    animation: loading-spin 2s linear infinite;
    box-sizing: border-box;
    width: 6rem !important;
    height: 6rem !important;

    &::after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }

  @keyframes loading-spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
}
</style>
