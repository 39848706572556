<template>
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1_16)">
      <path
        d="M7.79799 5.49417C7.62091 4.94083 7.24341 4.41958 6.66674 3.95833C5.83341 3.29167 5.20841 2.29167 5.00008 1.25C4.92386 1.62343 4.80007 1.98555 4.63174 2.3275"
        stroke="currentColor"
        stroke-width="0.833333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M0.83313 0.83313L9.16646 9.16646"
        stroke="currentColor"
        stroke-width="0.833333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.66438 3.66537C3.55874 3.76829 3.44819 3.86604 3.33313 3.95829C2.4998 4.62496 2.08313 5.41662 2.08313 6.24996C2.08306 6.90394 2.30279 7.53898 2.70703 8.05307C3.11126 8.56716 3.67654 8.93049 4.31209 9.08469C4.94764 9.23889 5.61657 9.17502 6.21145 8.90334C6.80633 8.63165 7.29265 8.16793 7.5923 7.58663"
        stroke="currentColor"
        stroke-width="0.833333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_16">
        <rect width="10" height="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
