<template>
  <div
    class="va-fields"
    :class="{
      'vertical-field-group': hasFieldsVolume,
      'vertical-field-group-border': hasFieldsVolume && allowsCopies,
    }"
  >
    <component
      :is="field.type"
      v-for="(field, index) in componentFields"
      :key="field.fieldName"
      :data="componentData"
      :field-model="field"
      :class="getCssClass(index)"
      :parent-data="componentParentData"
      @updatedData="broadcast"
    />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    fields: {
      type: Array,
      default: null,
    },
    fieldsVolume: {
      type: Array,
      default: null,
    },
    allowsCopies: {
      type: Boolean,
    },
    parentData: {
      type: Object,
      default: null,
    },
  },
  emits: ['updatedData'],
  computed: {
    componentData() {
      return this.data;
    },

    componentFields() {
      return this.fields;
    },

    componentParentData() {
      return this.parentData;
    },

    hasFieldsVolume() {
      return this.fieldsVolume;
    },
  },

  created: function () {
    if (this.fields) {
      this.fields.forEach(function (field) {
        if (!this.data[field.fieldName]) {
          // TODO Fix no-mutating-props issue
          // eslint-disable-next-line vue/no-mutating-props
          this.data[field.fieldName] =
            field.fields && field.fieldName ? [] : '';
        }
      }, this);
    }
  },

  methods: {
    broadcast() {
      this.$emit('updatedData', this.data);
    },
    getCssClass(index) {
      if (this.fieldsVolume) {
        return 'col-' + this.fieldsVolume[index];
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.va-fields {
  .va-field {
    margin-bottom: 1.25rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .vertical-field-group {
    margin-left: 0;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 0.3rem;
    align-items: center;

    .va-field {
      margin-bottom: 0;
    }

    &.vertical-field-group-border {
      margin-left: 0.3rem;
    }
  }

  [class*='col-'] {
    vertical-align: top;

    &:last-child {
      padding-right: 0;
    }
  }

  @for $i from 1 through 12 {
    .col-#{$i}-12 {
      grid-column: span #{$i};
    }
  }

  :deep(.title-label) {
    color: $color-text;
    font-size: 0.75rem;
    line-height: 150%;
    position: relative;
    margin-bottom: 0.25rem;
    display: block;
    text-align: left;
  }
}
</style>
