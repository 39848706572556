<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.08333 5.33325H8.25C8.57217 5.33325 8.83333 5.59442 8.83333 5.91659V14.0833C8.83333 14.4054 8.57217 14.6666 8.25 14.6666H7.08333C6.76117 14.6666 6.5 14.4054 6.5 14.0833V5.91659C6.5 5.59442 6.76117 5.33325 7.08333 5.33325ZM11.75 5.33325H12.9167C13.2388 5.33325 13.5 5.59442 13.5 5.91659V14.0833C13.5 14.4054 13.2388 14.6666 12.9167 14.6666H11.75C11.4278 14.6666 11.1667 14.4054 11.1667 14.0833V5.91659C11.1667 5.59442 11.4278 5.33325 11.75 5.33325Z"
      fill="#181E24"
    />
  </svg>
</template>
