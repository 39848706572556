import { type User, UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { reactive } from 'vue';
import Env from '@/utils/env';

export class AuthService {
  userManager: UserManager;
  constructor() {
    const STS_DOMAIN = Env.getEnv('VITE_API_URL_IDENTITY_SERVER');

    const settings = {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority: STS_DOMAIN,
      client_id: 'signage-player-frontend-code',
      redirect_uri: Env.getEnv('VITE_API_URL_SIGNAGE_FRONTEND') + '/callback',
      response_type: 'code',
      scope: 'openid profile iuser offline_access',
      post_logout_redirect_uri:
        Env.getEnv('VITE_API_URL_SIGNAGE_FRONTEND') + '/',
      filterProtocolClaims: true,
      useRefreshToken: true,
      prompt: 'login',
    };

    this.userManager = new UserManager(settings);

    this.userManager.events.addUserLoaded((user) => {
      state.user = user;
    });
  }
}

const initialState: {
  isAuthenticated: boolean;
  user: User | null;
  mgr: UserManager;
} = {
  isAuthenticated: false,
  user: null,
  mgr: new AuthService().userManager,
};

const state = reactive(initialState);

export const userComputed = {
  isAuthenticated: {
    get() {
      return state.isAuthenticated;
    },
  },
  user: {
    get() {
      return state.user;
    },
  },
};

export const authenticate = async (returnPath: string) => {
  const user = await state.mgr.getUser(); //see if the user details are in local storage
  if (user) {
    state.isAuthenticated = true;
    state.user = user;
  } else {
    login(returnPath);
  }
};

export const login = (returnPath?: string) => {
  localStorage.removeItem('provider');
  if (returnPath) {
    state.mgr.signinRedirect({ state: returnPath });
  } else {
    state.mgr.signinRedirect();
  }
};

export const loginWithProvider = (provider: string, returnPath = '') => {
  localStorage.setItem('provider', provider);
  state.mgr.signinRedirect({
    state: returnPath ? returnPath : undefined,
    extraQueryParams: {
      provider: provider,
    },
  });
};

export const logout = () => {
  const provider = localStorage.getItem('provider');
  const postLogoutRedirectUri = provider
    ? `${Env.getEnv('VITE_API_URL_SIGNAGE_FRONTEND')}/logins/${provider}`
    : undefined;
  state.mgr.signoutRedirect({
    post_logout_redirect_uri: postLogoutRedirectUri,
  });
};

export const signinRedirectCallback = async () => {
  const result = await state.mgr.signinRedirectCallback();
  return result;
};
