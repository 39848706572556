import api from '@/api/api';

const URLS = {
  getMediaFilters: 'mediafilter',
  getMediaFilter: 'mediafilter/{filterCode}',
};

export const getMediaFilters = (
  { searchText, maxItemsInResult, skipItemsInResult, orderBy, order },
  config,
) => {
  return api.get(URLS.getMediaFilters, {
    params: {
      searchText: searchText,
      maxItemsInResult: maxItemsInResult,
      skipItemsInResult: skipItemsInResult,
      orderBy: orderBy,
      order: order,
    },
    ...config,
  });
};

export const getMediaFilter = (filterCode) => {
  return api.get(URLS.getMediaFilter.replace('{filterCode}', filterCode));
};
