<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.3737 6.08464C15.7642 6.47517 15.7642 7.10833 15.3737 7.49885L8.95703 13.9155C8.76949 14.1031 8.51513 14.2084 8.24992 14.2084C7.9847 14.2084 7.73035 14.1031 7.54281 13.9155L4.62615 10.9989C4.23562 10.6083 4.23562 9.97517 4.62615 9.58464C5.01667 9.19412 5.64983 9.19412 6.04036 9.58464L8.24992 11.7942L13.9595 6.08464C14.35 5.69412 14.9832 5.69412 15.3737 6.08464Z"
      fill="#181E24"
    />
  </svg>
</template>
