<template>
  <div class="va-field va-fieldset test">
    <template v-if="hasFields">
      <div class="fieldset-grid">
        <div v-for="(fieldGroupData, id) in fieldGroupsData" :key="id">
          <div
            role="button"
            class="title-container"
            :class="{ clickable: fieldModel.collapsible }"
            :data-testid="`fieldset-title-${fieldModel.fieldName}`"
            @click="updateCollapsible(fieldGroupData.key)"
          >
            <legend v-if="hasTitle">
              {{ getTitle(id) }}
            </legend>
            <svg-icon
              v-if="fieldModel.collapsible"
              icon="chevron"
              :class="[
                'collapsible-icon',
                { open: !collapsed.includes(fieldGroupData.key) },
              ]"
            />
          </div>
          <fieldset
            v-show="!collapsed.includes(fieldGroupData.key)"
            :key="id"
            :class="{
              'field-group': hasTitle,
              'vertical-field-group': hasFieldsVolume,
              'field-group-border': hasFieldsVolume && allowsCopies,
            }"
          >
            <va-fields
              :key="fieldGroupData.key"
              :data="fieldGroupData"
              :fields="modelFields"
              :fields-volume="fieldsVolume"
              :allows-copies="allowsCopies"
              :parent-data="componentData"
              @updatedData="broadcast"
            />
            <template
              v-if="!hasFieldsVolume || (hasFieldsVolume && allowsCopies)"
            >
              <div class="va-fieldset-copies">
                <div v-if="canBeRemoved" class="remove" @click="remove(id)">
                  <svg-icon icon="remove" color="pink" />
                </div>
                <div v-if="canRepeat" class="plus" @click="plus(id)">
                  <svg-icon icon="add" color="green" />
                </div>
              </div>
            </template>
          </fieldset>
        </div>
      </div>
    </template>

    <template v-else>
      <fieldset :class="{ 'field-group': hasTitle }">
        <legend v-if="hasTitle">
          {{ getTitle(0) }}
        </legend>
        <va-fields
          :data="componentData"
          :fields="modelFields"
          @updatedData="broadcast"
        />
      </fieldset>
    </template>
  </div>
</template>

<script>
import formHelper from '@/helpers/form-helper';
import SvgIcon from '@/components/icons/SvgIcon.vue';
import { v4 as uuidv4 } from 'uuid';
export default {
  components: {
    SvgIcon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    fieldModel: {
      type: Object,
      required: true,
    },
  },
  emits: ['updatedData'],
  data() {
    return {
      collapsed: [],
    };
  },
  computed: {
    componentData: function () {
      return this.data;
    },

    fieldGroupsData: function () {
      let fieldGroupsData = this.data[this.fieldModel.fieldName] || [];
      if (!fieldGroupsData.length) {
        fieldGroupsData.push(formHelper.createEmptyChildNode(this.fieldModel));
      }
      fieldGroupsData.forEach((data) => {
        if (!data.key) {
          data.key = uuidv4();
        }
      });
      return fieldGroupsData;
    },

    modelFields: function () {
      return this.fieldModel.fields;
    },

    hasTitle: function () {
      return this.fieldModel.title && this.fieldModel.title !== '';
    },

    hasFields: function () {
      return this.modelFields && this.fieldModel.fieldName;
    },

    copies: function () {
      if (this.hasFields) {
        return this.fieldGroupsData.length;
      }
      return 1;
    },

    canRepeat: function () {
      return this.copies < this.fieldModel.maxCopies;
    },

    canBeRemoved: function () {
      return this.fieldModel.maxCopies && this.copies > 1;
    },

    hasFieldsVolume() {
      return this.fieldModel.style && this.fieldModel.style.fieldsVolume;
    },

    allowsCopies() {
      return this.fieldModel.style && this.fieldModel.style.allowCopies;
    },
    // eslint-disable-next-line
    fieldsVolume() {
      if (this.fieldModel.style) {
        return this.fieldModel.style.fieldsVolume;
      }
    },
  },

  created() {
    if (!this.data) {
      // TODO Fix no-mutating-props issue
      // eslint-disable-next-line vue/no-mutating-props
      this.data = [];
    }
  },

  mounted() {
    if (this.fieldModel.fieldName && !this.data[this.fieldModel.fieldName]) {
      // eslint-disable-next-line vue/no-mutating-props
      this.data[this.fieldModel.fieldName] = [
        formHelper.createEmptyChildNode(this.fieldModel),
      ];
    }

    this.fieldGroupsData.forEach((g) => {
      if (this.fieldModel.isCollapsed) {
        this.collapsed.push(g.key);
      }
    });
  },

  methods: {
    broadcast() {
      this.$emit('updatedData', this.data);
    },
    plus(id) {
      if (this.copies < this.fieldModel.maxCopies) {
        this.fieldGroupsData.splice(
          id + 1,
          0,
          formHelper.createEmptyChildNode(this.fieldModel),
        );
        this.$emit('updatedData');
      }
    },
    remove(id) {
      this.fieldGroupsData.splice(id, 1);
      this.$emit('updatedData');
    },
    updateCollapsible(key) {
      if (this.collapsed.includes(key)) {
        this.collapsed = this.collapsed.filter((e) => e !== key);
      } else {
        this.collapsed.push(key);
      }
    },
    getTitle(index) {
      return formHelper.getFieldSetTitle(
        this.fieldModel,
        this.data,
        this.$store.getters.formFacts,
        index,
        this.$getUserlanguage(),
      );
    },
  },
};
</script>

<style lang="scss">
fieldset {
  --hover-on-delay: 0s;
  --hover-off-delay: 0.5s;

  font-family: inherit;
  position: relative;
  border: 0;
  padding: 0;
  padding-left: 0;
  transition: padding-left 0.2s ease;
  transition-delay: var(--hover-off-delay);
  margin: 0;
  box-sizing: border-box;

  legend {
    font-weight: 500;
    color: $color-text-secondary;
    position: relative;
    top: 0;
    text-align: left;
    padding: 0.5rem 0;
  }

  .title-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    pointer-events: none;

    &.clickable {
      pointer-events: auto;
      cursor: pointer;

      &:hover {
        * {
          color: $color-text;
        }
      }
    }

    legend {
      width: 100%;
    }
  }

  .collapsible-icon {
    cursor: pointer;
    color: $color-text-secondary;
    display: inline-block;
    padding: 0.5rem 0;

    &.open {
      transform: rotate(180deg);
    }
  }

  .vertical-field-group {
    border-left: 0;
  }

  &.field-group {
    margin-top: 0;
  }

  .va-fieldset-copies {
    display: flex;
    box-sizing: border-box;
    position: absolute;
    border-color: $color-border;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    left: 0;
    top: 0;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 10px;
    opacity: 0;
    transition: opacity 0.2s ease;
    transition-delay: var(--hover-off-delay);
  }

  .plus,
  .remove {
    display: flex;
    cursor: pointer;
    margin-left: -9.5px;
    padding: 5px 0;
    font-size: 19px;

    &:hover {
      transform: scale(1.1);
      z-index: 2;
    }
  }

  &:hover {
    padding-left: 20px;
    transition-delay: var(--hover-on-delay);

    &.vertical-field-group {
      &:not(.field-group-border) {
        padding-left: 0;
      }
    }

    > .va-fieldset-copies {
      opacity: 1;
      transition-delay: var(--hover-on-delay);
    }
  }

  /* TODO Fix lint error */
  /* stylelint-disable-next-line no-descending-specificity */
  fieldset {
    margin-bottom: 0.3rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .fieldset-grid {
    display: grid;
    gap: 1.2rem;
  }
}
</style>
