// TODO Fix no-unused-vars problem by removing actions and call methods inside component instead
const SET_ACTIVE_VIEW_LAYOUT = 'SET_ACTIVE_VIEW_LAYOUT';
const SET_DRAGGING = 'SET_DRAGGING';
const SET_PINNED = 'SET_PINNED';
const SET_DRAWER = 'SET_DRAWER';

// state
const state = {
  activeViewLayout: 'list',
  dragging: false,
  pinned: true,
  drawer: false,
};

// getters
const getters = {
  activeViewLayout: (state) => state.activeViewLayout,
  dragging: (state) => state.dragging,
  pinned: (state) => state.pinned,
  drawer: (state) => state.drawer,
};

// actions
const actions = {
  //used globally
  changeActiveViewLayout({ commit }, { activeViewLayout }) {
    commit(SET_ACTIVE_VIEW_LAYOUT, { activeViewLayout });
  },
  setDraggingState({ commit }, { dragging }) {
    commit(SET_DRAGGING, { dragging });
  },
  setPinnedState({ commit }, { pinned }) {
    commit(SET_PINNED, { pinned });
  },
  setDrawerState({ commit }, { drawer }) {
    commit(SET_DRAWER, { drawer });
  },
};

// mutations
const mutations = {
  [SET_ACTIVE_VIEW_LAYOUT](state, { activeViewLayout }) {
    state.activeViewLayout = activeViewLayout;
  },
  [SET_DRAGGING](state, { dragging }) {
    state.dragging = dragging;
  },
  [SET_PINNED](state, { pinned }) {
    state.pinned = pinned;
  },
  [SET_DRAWER](state, { drawer }) {
    state.drawer = drawer;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
