// TODO Fix no-unused-vars problem by removing actions and call methods inside component instead
import userServices from '@/services/user';
import store from '@/store';

const SET_USERS = 'SET_USERS';
const ADD_USERS = 'ADD_USERS';
const REMOVE_USER = 'REMOVE_USER';
const ADD_USER = 'ADD_USER';
const UPDATE_USER = 'UPDATE_USER';

// state
const state = {
  users: [],
};

// getters
const getters = {
  users: (state) => state.users,
};

// actions
const actions = {
  getUsers({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      userServices
        .getUsers(
          parameters.searchText,
          parameters.orderBy,
          parameters.order,
          parameters.maxItemsInResult,
          parameters.skipItemsInResult,
          parameters.role,
        )
        .then((users) => {
          if (parameters.skipItemsInResult) {
            commit(ADD_USERS, { users });
          } else {
            commit(SET_USERS, { users });
          }
          resolve(users);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  inviteUser({ commit, dispatch }, { newUser }) {
    return new Promise((resolve, reject) => {
      if (newUser) {
        userServices
          .inviteUser(newUser)
          .then((user) => {
            if (user.success.ok == true) {
              commit(ADD_USER, { user });
              dispatch('getUsers', {});
              dispatch('addMessage', {
                message: {
                  text: 'Added new user ' + user.email,
                  type: 'success',
                },
                time: 10000,
              });
            } else {
              dispatch('addMessage', {
                message: { text: user.success.message, type: 'error' },
                time: 10000,
              });
            }
            resolve(user);
          })
          .catch((error) => {
            if (error.response.status == 500) {
              dispatch('addMessage', {
                message: { text: error.response.data, type: 'error' },
                time: 10000,
              });
            } else {
              dispatch('addMessage', {
                message: { text: error.message, type: 'error' },
                time: 10000,
              });
            }
            reject(error);
          });
      }
    });
  },

  resetPasswordOrInvitation({ dispatch }, { user }) {
    let langData = store.getters.langData[store.getters.langData.selected];

    if (user) {
      userServices
        .resetPasswordOrInvitation(user.userId)
        .then((response) => {
          if (response.success.ok) {
            if (user.isActive) {
              dispatch('addMessage', {
                message: {
                  text:
                    langData.users.messages.resetPassword + ' ' + user.email,
                  type: 'success',
                },
                time: 10000,
              });
            } else {
              dispatch('addMessage', {
                message: {
                  text: langData.users.messages.renewInvite + ' ' + user.email,
                  type: 'success',
                },
                time: 10000,
              });
            }
          } else {
            dispatch('addMessage', {
              message: { text: response.success.message, type: 'error' },
              time: 10000,
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },

  modifyOwner({ dispatch }, { fromOwner, toUser }) {
    return new Promise((resolve, reject) => {
      if (fromOwner && toUser) {
        userServices
          .modifyOwner(fromOwner.UserDetails.id, toUser.userId)
          .then((response) => {
            if (response.success.ok) {
              dispatch('getUser');
              dispatch('getUsers', {});
            } else {
              dispatch('addMessage', {
                message: { text: response.success.message, type: 'error' },
                time: 10000,
              });
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getOwner({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      userServices
        .getOwner()
        .then((owner) => {
          resolve(owner);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
  [SET_USERS](state, { users }) {
    state.users = users;
  },
  [ADD_USERS](state, { users }) {
    // Do nothing is there are no users to add
    if (!users) {
      return;
    }

    // Add all users directly if there state is empty
    if (users && !state.users) {
      state.users = users;
      return;
    }

    users.forEach((newUser) => {
      if (!state.users.map((user) => user.userId).includes(newUser.userId)) {
        state.users.push(newUser);
      }
    });
  },
  [REMOVE_USER](state, { userId }) {
    if (state.users) {
      let index = state.users.findIndex((obj) => obj.userId === userId);
      state.users.splice(index, 1);
    }
  },
  [ADD_USER](state, { user }) {
    state.users.push(user);
  },
  [UPDATE_USER](state, { user }) {
    if (state.users) {
      let index = state.users.findIndex((obj) => obj.userId === user.UserCode);
      state.users[index].firstName = user.FirstName;
      state.users[index].lastName = user.LastName;
      state.users[index].email = user.Email;
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
