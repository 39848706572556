<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1_38)">
      <path
        d="M7.93021 0.500244C7.9814 0.499797 8.0326 0.499841 8.08379 0.500377L8.00002 8.49994L7.93021 0.500244Z"
        fill="#FF0000"
      />
      <path
        d="M8.06981 0.500305C8.12101 0.500752 8.1722 0.50169 8.22337 0.50312L8 8.5L8.06981 0.500305Z"
        fill="#FF0400"
      />
      <path
        d="M8.20941 0.502747C8.26059 0.504087 8.31176 0.505918 8.3629 0.50824L8 8.50001L8.20941 0.502747Z"
        fill="#FF0900"
      />
      <path
        d="M8.34895 0.507599C8.4001 0.509832 8.45123 0.512556 8.50232 0.515771L8 8.49998L8.34895 0.507599Z"
        fill="#FF0D00"
      />
      <path
        d="M8.48839 0.514923C8.53949 0.518049 8.59056 0.521665 8.64159 0.525771L8 8.5L8.48839 0.514923Z"
        fill="#FF1100"
      />
      <path
        d="M8.62767 0.524658C8.67871 0.528675 8.72971 0.533182 8.78066 0.538178L8 8.5L8.62767 0.524658Z"
        fill="#FF1500"
      />
      <path
        d="M8.76677 0.536835C8.81773 0.541742 8.86864 0.547138 8.9195 0.553022L8 8.5L8.76677 0.536835Z"
        fill="#FF1A00"
      />
      <path
        d="M8.90563 0.551422C8.95649 0.557218 9.0073 0.563501 9.05805 0.570272L8 8.5L8.90563 0.551422Z"
        fill="#FF1E00"
      />
      <path
        d="M9.04421 0.56842C9.09497 0.575103 9.14566 0.582273 9.19628 0.589928L8 8.49998L9.04421 0.56842Z"
        fill="#FF2200"
      />
      <path
        d="M9.18248 0.58786C9.23311 0.595427 9.28367 0.603481 9.33415 0.612019L8 8.49999L9.18248 0.58786Z"
        fill="#FF2600"
      />
      <path
        d="M9.32038 0.609711C9.37088 0.618161 9.42129 0.627095 9.47161 0.636513L8 8.5L9.32038 0.609711Z"
        fill="#FF2B00"
      />
      <path
        d="M9.45788 0.633942C9.50822 0.643272 9.55847 0.653084 9.60862 0.663379L8 8.49998L9.45788 0.633942Z"
        fill="#FF2F00"
      />
      <path
        d="M9.59494 0.660583C9.64511 0.670791 9.69518 0.681479 9.74515 0.692647L8 8.49998L9.59494 0.660583Z"
        fill="#FF3300"
      />
      <path
        d="M9.73152 0.689636C9.7815 0.700717 9.83138 0.712278 9.88114 0.724316L8 8.5L9.73152 0.689636Z"
        fill="#FF3700"
      />
      <path
        d="M9.86756 0.721039C9.91734 0.732991 9.96701 0.74542 10.0166 0.758325L8 8.5L9.86756 0.721039Z"
        fill="#FF3C00"
      />
      <path
        d="M10.003 0.754822C10.0526 0.76764 10.102 0.780934 10.1514 0.794702L8 8.5L10.003 0.754822Z"
        fill="#FF4000"
      />
      <path
        d="M10.1379 0.790955C10.1872 0.804636 10.2364 0.818791 10.2855 0.833418L8 8.5L10.1379 0.790955Z"
        fill="#FF4400"
      />
      <path
        d="M10.2721 0.829437C10.3212 0.843978 10.3702 0.858989 10.419 0.87447L8 8.49999L10.2721 0.829437Z"
        fill="#FF4800"
      />
      <path
        d="M10.4056 0.87027C10.4545 0.885665 10.5032 0.901529 10.5517 0.917858L8 8.50001L10.4056 0.87027Z"
        fill="#FF4D00"
      />
      <path
        d="M10.5384 0.913391C10.587 0.929636 10.6354 0.946347 10.6836 0.963521L8 8.49998L10.5384 0.913391Z"
        fill="#FF5100"
      />
      <path
        d="M10.6705 0.958862C10.7187 0.975952 10.7668 0.993505 10.8147 1.01152L8 8.49999L10.6705 0.958862Z"
        fill="#FF5500"
      />
      <path
        d="M10.8017 1.00662C10.8496 1.02455 10.8974 1.04294 10.945 1.06179L8 8.5L10.8017 1.00662Z"
        fill="#FF5900"
      />
      <path
        d="M10.932 1.05664C10.9796 1.0754 11.0271 1.09462 11.0744 1.1143L8 8.49998L10.932 1.05664Z"
        fill="#FF5E00"
      />
      <path
        d="M11.0615 1.10895C11.1088 1.12854 11.1559 1.14859 11.2028 1.16908L8 8.49998L11.0615 1.10895Z"
        fill="#FF6200"
      />
      <path
        d="M11.19 1.16351C11.2369 1.18393 11.2837 1.20479 11.3302 1.2261L8 8.49999L11.19 1.16351Z"
        fill="#FF6600"
      />
      <path
        d="M11.3175 1.22031C11.3641 1.24154 11.4105 1.26322 11.4567 1.28534L8 8.5L11.3175 1.22031Z"
        fill="#FF6A00"
      />
      <path
        d="M11.4441 1.2793C11.4903 1.30134 11.5363 1.32382 11.5821 1.34675L8 8.49998L11.4441 1.2793Z"
        fill="#FF6F00"
      />
      <path
        d="M11.5696 1.34052C11.6154 1.36336 11.661 1.38664 11.7064 1.41036L8 8.49999L11.5696 1.34052Z"
        fill="#FF7300"
      />
      <path
        d="M11.694 1.4039C11.7394 1.42754 11.7846 1.45162 11.8295 1.47612L8 8.49999L11.694 1.4039Z"
        fill="#FF7700"
      />
      <path
        d="M11.8173 1.46945C11.8623 1.49388 11.907 1.51874 11.9515 1.54403L8 8.49999L11.8173 1.46945Z"
        fill="#FF7B00"
      />
      <path
        d="M11.9394 1.53714C11.9839 1.56235 12.0283 1.58799 12.0723 1.61405L8 8.49999L11.9394 1.53714Z"
        fill="#FF8000"
      />
      <path
        d="M12.0603 1.60696C12.1044 1.63295 12.1483 1.65936 12.1919 1.68618L8 8.5L12.0603 1.60696Z"
        fill="#FF8400"
      />
      <path
        d="M12.18 1.67886C12.2236 1.70561 12.267 1.73278 12.3102 1.76037L8 8.49999L12.18 1.67886Z"
        fill="#FF8800"
      />
      <path
        d="M12.2984 1.75287C12.3416 1.78038 12.3845 1.8083 12.4271 1.83663L8 8.5L12.2984 1.75287Z"
        fill="#FF8C00"
      />
      <path
        d="M12.4155 1.82892C12.4582 1.85718 12.5006 1.88584 12.5427 1.91491L8 8.5L12.4155 1.82892Z"
        fill="#FF9100"
      />
      <path
        d="M12.5312 1.90698C12.5734 1.93598 12.6154 1.96538 12.657 1.99519L8 8.49999L12.5312 1.90698Z"
        fill="#FF9500"
      />
      <path
        d="M12.6456 1.98706C12.6873 2.01679 12.7287 2.04692 12.7698 2.07744L8 8.49998L12.6456 1.98706Z"
        fill="#FF9900"
      />
      <path
        d="M12.7586 2.06915C12.7997 2.09961 12.8406 2.13045 12.8812 2.16169L8 8.50001L12.7586 2.06915Z"
        fill="#FF9D00"
      />
      <path
        d="M12.8701 2.15317C12.9107 2.18433 12.951 2.21589 12.991 2.24783L8 8.49999L12.8701 2.15317Z"
        fill="#FFA200"
      />
      <path
        d="M12.9801 2.23914C13.0202 2.27101 13.0599 2.30326 13.0994 2.3359L8 8.5L12.9801 2.23914Z"
        fill="#FFA600"
      />
      <path
        d="M13.0886 2.327C13.1281 2.35956 13.1673 2.3925 13.2062 2.42582L8 8.49999L13.0886 2.327Z"
        fill="#FFAA00"
      />
      <path
        d="M13.1956 2.41675C13.2345 2.45 13.2731 2.48362 13.3114 2.51761L8 8.5L13.1956 2.41675Z"
        fill="#FFAE00"
      />
      <path
        d="M13.301 2.50836C13.3393 2.54229 13.3773 2.57658 13.415 2.61123L8 8.50001L13.301 2.50836Z"
        fill="#FFB300"
      />
      <path
        d="M13.4047 2.60178C13.4425 2.63636 13.4799 2.67131 13.517 2.70662L8 8.49999L13.4047 2.60178Z"
        fill="#FFB700"
      />
      <path
        d="M13.5068 2.69699C13.544 2.73223 13.5808 2.76783 13.6172 2.80378L8 8.49999L13.5068 2.69699Z"
        fill="#FFBB00"
      />
      <path
        d="M13.5068 2.69699C13.544 2.73223 13.5808 2.76783 13.6172 2.80378L8 8.49999L13.5068 2.69699Z"
        fill="#FFBB00"
      />
      <path
        d="M13.6073 2.79398C13.6438 2.82986 13.68 2.86609 13.7158 2.90267L8 8.49998L13.6073 2.79398Z"
        fill="#FFBF00"
      />
      <path
        d="M13.706 2.89273C13.7419 2.92925 13.7774 2.96611 13.8126 3.0033L8 8.5L13.706 2.89273Z"
        fill="#FFC400"
      />
      <path
        d="M13.803 2.99316C13.8382 3.0303 13.8731 3.06778 13.9076 3.10558L8 8.5L13.803 2.99316Z"
        fill="#FFC800"
      />
      <path
        d="M13.8982 3.09528C13.9328 3.13302 13.967 3.1711 14.0009 3.2095L8 8.5L13.8982 3.09528Z"
        fill="#FFCC00"
      />
      <path
        d="M13.9916 3.19904C14.0256 3.23738 14.0591 3.27605 14.0923 3.31504L8 8.5L13.9916 3.19904Z"
        fill="#FFD000"
      />
      <path
        d="M14.0832 3.30441C14.1165 3.34334 14.1494 3.38259 14.1819 3.42215L8 8.5L14.0832 3.30441Z"
        fill="#FFD500"
      />
      <path
        d="M14.173 3.41138C14.2056 3.45088 14.2377 3.4907 14.2695 3.53082L8 8.5L14.173 3.41138Z"
        fill="#FFD900"
      />
      <path
        d="M14.2609 3.51987C14.2927 3.55993 14.3242 3.60031 14.3553 3.64098L8 8.49998L14.2609 3.51987Z"
        fill="#FFDD00"
      />
      <path
        d="M14.3468 3.62991C14.378 3.67053 14.4088 3.71145 14.4392 3.75265L8 8.50001L14.3468 3.62991Z"
        fill="#FFE100"
      />
      <path
        d="M14.4309 3.74142C14.4613 3.78258 14.4914 3.82403 14.521 3.86576L8 8.50001L14.4309 3.74142Z"
        fill="#FFE600"
      />
      <path
        d="M14.5129 3.85437C14.5427 3.89605 14.572 3.93801 14.6009 3.98026L8 8.49999L14.5129 3.85437Z"
        fill="#FFEA00"
      />
      <path
        d="M14.593 3.96875C14.622 4.01094 14.6506 4.05341 14.6788 4.09615L8 8.5L14.593 3.96875Z"
        fill="#FFEE00"
      />
      <path
        d="M14.6711 4.0845C14.6993 4.1272 14.7272 4.17016 14.7546 4.21338L8 8.5L14.6711 4.0845Z"
        fill="#FFF200"
      />
      <path
        d="M14.7471 4.2016C14.7746 4.24478 14.8017 4.28822 14.8284 4.33192L8 8.5L14.7471 4.2016Z"
        fill="#FFF700"
      />
      <path
        d="M14.8211 4.32001C14.8479 4.36366 14.8742 4.40757 14.9001 4.45173L8 8.5L14.8211 4.32001Z"
        fill="#FFFB00"
      />
      <path
        d="M14.893 4.4397C14.919 4.48381 14.9446 4.52817 14.9697 4.57277L8 8.5L14.893 4.4397Z"
        fill="#FFFF00"
      />
      <path
        d="M14.9628 4.56061C14.9881 4.60517 15.0128 4.64997 15.0372 4.695L8 8.5L14.9628 4.56061Z"
        fill="#FBFF00"
      />
      <path
        d="M15.0305 4.68271C15.055 4.7277 15.079 4.77293 15.1025 4.81838L8 8.49998L15.0305 4.68271Z"
        fill="#F7FF00"
      />
      <path
        d="M15.0961 4.806C15.1197 4.85141 15.1429 4.89705 15.1657 4.94291L8 8.49999L15.0961 4.806Z"
        fill="#F2FF00"
      />
      <path
        d="M15.1595 4.93042C15.1823 4.97624 15.2047 5.02227 15.2267 5.06852L8 8.5L15.1595 4.93042Z"
        fill="#EEFF00"
      />
      <path
        d="M15.2207 5.05591C15.2427 5.10212 15.2643 5.14854 15.2855 5.19516L8 8.5L15.2207 5.05591Z"
        fill="#EAFF00"
      />
      <path
        d="M15.2797 5.18243C15.3009 5.22902 15.3217 5.27581 15.342 5.3228L8 8.49998L15.2797 5.18243Z"
        fill="#E6FF00"
      />
      <path
        d="M15.3365 5.31C15.3569 5.35695 15.3769 5.40409 15.3964 5.45143L8 8.49999L15.3365 5.31Z"
        fill="#E1FF00"
      />
      <path
        d="M15.391 5.43854C15.4106 5.48584 15.4298 5.53332 15.4484 5.58099L8 8.50001L15.391 5.43854Z"
        fill="#DDFF00"
      />
      <path
        d="M15.4433 5.56799C15.4621 5.61563 15.4804 5.66344 15.4983 5.71143L8 8.5L15.4433 5.56799Z"
        fill="#D9FF00"
      />
      <path
        d="M15.4934 5.69833C15.5113 5.74629 15.5288 5.79441 15.5458 5.8427L8 8.49999L15.4934 5.69833Z"
        fill="#D5FF00"
      />
      <path
        d="M15.5411 5.82953C15.5582 5.87779 15.5748 5.92621 15.591 5.97479L8 8.49998L15.5411 5.82953Z"
        fill="#D0FF00"
      />
      <path
        d="M15.5866 5.96155C15.6028 6.0101 15.6186 6.0588 15.6339 6.10766L8 8.49998L15.5866 5.96155Z"
        fill="#CCFF00"
      />
      <path
        d="M15.6297 6.09436C15.6451 6.14319 15.6601 6.19216 15.6745 6.24127L8 8.50001L15.6297 6.09436Z"
        fill="#C8FF00"
      />
      <path
        d="M15.6706 6.22787C15.6851 6.27696 15.6992 6.32619 15.7128 6.37555L8 8.5L15.6706 6.22787Z"
        fill="#C4FF00"
      />
      <path
        d="M15.709 6.36209C15.7227 6.41143 15.7359 6.46089 15.7487 6.51048L8 8.5L15.709 6.36209Z"
        fill="#BFFF00"
      />
      <path
        d="M15.7452 6.49695C15.758 6.54651 15.7703 6.5962 15.7822 6.646L8 8.49999L15.7452 6.49695Z"
        fill="#BBFF00"
      />
      <path
        d="M15.779 6.63242C15.7909 6.6822 15.8024 6.73209 15.8134 6.7821L8 8.49998L15.779 6.63242Z"
        fill="#B7FF00"
      />
      <path
        d="M15.8104 6.76846C15.8214 6.81845 15.8321 6.86853 15.8422 6.91872L8 8.49998L15.8104 6.76846Z"
        fill="#B3FF00"
      />
      <path
        d="M15.8394 6.90506C15.8496 6.95523 15.8593 7.00549 15.8686 7.05585L8 8.5L15.8394 6.90506Z"
        fill="#AEFF00"
      />
      <path
        d="M15.866 7.04211C15.8754 7.09245 15.8842 7.14288 15.8926 7.19339L8 8.5L15.866 7.04211Z"
        fill="#AAFF00"
      />
      <path
        d="M15.8903 7.17963C15.8987 7.23012 15.9067 7.2807 15.9142 7.33134L8 8.50001L15.8903 7.17963Z"
        fill="#A6FF00"
      />
      <path
        d="M15.9121 7.3175C15.9197 7.36814 15.9268 7.41884 15.9334 7.46962L8 8.49998L15.9121 7.3175Z"
        fill="#A2FF00"
      />
      <path
        d="M15.9316 7.45578C15.9382 7.50654 15.9444 7.55736 15.9501 7.60824L8 8.49999L15.9316 7.45578Z"
        fill="#9EFF00"
      />
      <path
        d="M15.9486 7.59436C15.9544 7.64523 15.9597 7.69615 15.9645 7.74712L8 8.49999L15.9486 7.59436Z"
        fill="#99FF00"
      />
      <path
        d="M15.9632 7.73322C15.9681 7.78418 15.9725 7.83518 15.9764 7.88623L8 8.49998L15.9632 7.73322Z"
        fill="#95FF00"
      />
      <path
        d="M15.9753 7.87231C15.9794 7.92335 15.9829 7.97443 15.9859 8.02554L8 8.49999L15.9753 7.87231Z"
        fill="#91FF00"
      />
      <path
        d="M15.9851 8.0116C15.9882 8.0627 15.9908 8.11383 15.993 8.16498L8 8.49998L15.9851 8.0116Z"
        fill="#8CFF00"
      />
      <path
        d="M15.9924 8.15103C15.9946 8.20218 15.9964 8.25335 15.9976 8.30453L8 8.49999L15.9924 8.15103Z"
        fill="#88FF00"
      />
      <path
        d="M15.9973 8.29059C15.9986 8.34177 15.9994 8.39296 15.9998 8.44415L8 8.5L15.9973 8.29059Z"
        fill="#84FF00"
      />
      <path
        d="M15.9997 8.43018C16.0002 8.48137 16.0001 8.53257 15.9996 8.58376L8.00003 8.49999L15.9997 8.43018Z"
        fill="#80FF00"
      />
      <path
        d="M15.9997 8.56981C15.9992 8.62101 15.9983 8.6722 15.9969 8.72337L8 8.5L15.9997 8.56981Z"
        fill="#7BFF00"
      />
      <path
        d="M15.9973 8.70942C15.9959 8.76059 15.9941 8.81176 15.9918 8.8629L8 8.5L15.9973 8.70942Z"
        fill="#77FF00"
      />
      <path
        d="M15.9924 8.84895C15.9902 8.9001 15.9874 8.95123 15.9842 9.00232L8 8.5L15.9924 8.84895Z"
        fill="#73FF00"
      />
      <path
        d="M15.9851 8.98839C15.982 9.03949 15.9783 9.09056 15.9742 9.14159L8 8.5L15.9851 8.98839Z"
        fill="#6FFF00"
      />
      <path
        d="M15.9753 9.12767C15.9713 9.17871 15.9668 9.22971 15.9618 9.28066L8 8.5L15.9753 9.12767Z"
        fill="#6AFF00"
      />
      <path
        d="M15.9632 9.26677C15.9583 9.31773 15.9529 9.36864 15.947 9.4195L8 8.5L15.9632 9.26677Z"
        fill="#66FF00"
      />
      <path
        d="M15.9486 9.40563C15.9428 9.45649 15.9365 9.5073 15.9297 9.55805L8 8.5L15.9486 9.40563Z"
        fill="#62FF00"
      />
      <path
        d="M15.9316 9.54421C15.9249 9.59497 15.9177 9.64566 15.9101 9.69628L8 8.5L15.9316 9.54421Z"
        fill="#5EFF00"
      />
      <path
        d="M15.9121 9.68248C15.9046 9.73311 15.8965 9.78367 15.888 9.83415L8 8.5L15.9121 9.68248Z"
        fill="#59FF00"
      />
      <path
        d="M15.8903 9.82038C15.8818 9.87088 15.8729 9.92129 15.8635 9.97161L8 8.5L15.8903 9.82038Z"
        fill="#55FF00"
      />
      <path
        d="M15.866 9.95788C15.8567 10.0082 15.8469 10.0585 15.8366 10.1086L8 8.5L15.866 9.95788Z"
        fill="#51FF00"
      />
      <path
        d="M15.8394 10.0949C15.8292 10.1451 15.8185 10.1952 15.8073 10.2451L8 8.5L15.8394 10.0949Z"
        fill="#4DFF00"
      />
      <path
        d="M15.8104 10.2315C15.7993 10.2815 15.7877 10.3314 15.7757 10.3811L8 8.5L15.8104 10.2315Z"
        fill="#48FF00"
      />
      <path
        d="M15.779 10.3676C15.767 10.4173 15.7546 10.467 15.7417 10.5166L8 8.5L15.779 10.3676Z"
        fill="#44FF00"
      />
      <path
        d="M15.7452 10.503C15.7324 10.5526 15.7191 10.602 15.7053 10.6514L8 8.5L15.7452 10.503Z"
        fill="#40FF00"
      />
      <path
        d="M15.709 10.6379C15.6954 10.6872 15.6812 10.7364 15.6666 10.7855L8 8.5L15.709 10.6379Z"
        fill="#3CFF00"
      />
      <path
        d="M15.6706 10.7721C15.656 10.8212 15.641 10.8702 15.6255 10.919L8 8.5L15.6706 10.7721Z"
        fill="#37FF00"
      />
      <path
        d="M15.6297 10.9056C15.6143 10.9545 15.5985 11.0032 15.5821 11.0517L8 8.5L15.6297 10.9056Z"
        fill="#33FF00"
      />
      <path
        d="M15.5866 11.0384C15.5703 11.087 15.5536 11.1354 15.5365 11.1836L8 8.5L15.5866 11.0384Z"
        fill="#2FFF00"
      />
      <path
        d="M15.5411 11.1705C15.524 11.2187 15.5065 11.2668 15.4885 11.3147L8 8.5L15.5411 11.1705Z"
        fill="#2BFF00"
      />
      <path
        d="M15.4934 11.3017C15.4754 11.3496 15.4571 11.3974 15.4382 11.445L8 8.5L15.4934 11.3017Z"
        fill="#26FF00"
      />
      <path
        d="M15.4433 11.432C15.4246 11.4796 15.4054 11.5271 15.3857 11.5744L8 8.5L15.4433 11.432Z"
        fill="#22FF00"
      />
      <path
        d="M15.391 11.5615C15.3714 11.6088 15.3514 11.6559 15.3309 11.7028L8 8.5L15.391 11.5615Z"
        fill="#1EFF00"
      />
      <path
        d="M15.3365 11.69C15.3161 11.7369 15.2952 11.7837 15.2739 11.8302L8 8.5L15.3365 11.69Z"
        fill="#1AFF00"
      />
      <path
        d="M15.2797 11.8175C15.2585 11.8641 15.2368 11.9105 15.2147 11.9567L8 8.5L15.2797 11.8175Z"
        fill="#15FF00"
      />
      <path
        d="M15.2207 11.9441C15.1986 11.9903 15.1762 12.0363 15.1532 12.0821L8 8.5L15.2207 11.9441Z"
        fill="#11FF00"
      />
      <path
        d="M15.1595 12.0696C15.1366 12.1154 15.1133 12.161 15.0896 12.2064L8 8.5L15.1595 12.0696Z"
        fill="#0DFF00"
      />
      <path
        d="M15.0961 12.194C15.0724 12.2394 15.0484 12.2846 15.0239 12.3295L8 8.5L15.0961 12.194Z"
        fill="#09FF00"
      />
      <path
        d="M15.0305 12.3173C15.0061 12.3623 14.9812 12.407 14.956 12.4515L8 8.5L15.0305 12.3173Z"
        fill="#04FF00"
      />
      <path
        d="M14.9628 12.4394C14.9376 12.4839 14.912 12.5283 14.8859 12.5723L8 8.5L14.9628 12.4394Z"
        fill="#00FF00"
      />
      <path
        d="M14.893 12.5603C14.867 12.6044 14.8406 12.6483 14.8138 12.6919L8 8.5L14.893 12.5603Z"
        fill="#00FF04"
      />
      <path
        d="M14.8211 12.68C14.7944 12.7236 14.7672 12.767 14.7396 12.8102L8 8.5L14.8211 12.68Z"
        fill="#00FF09"
      />
      <path
        d="M14.7471 12.7984C14.7196 12.8416 14.6917 12.8845 14.6634 12.9271L8 8.5L14.7471 12.7984Z"
        fill="#00FF0D"
      />
      <path
        d="M14.6711 12.9155C14.6428 12.9582 14.6142 13.0006 14.5851 13.0427L8 8.5L14.6711 12.9155Z"
        fill="#00FF11"
      />
      <path
        d="M14.593 13.0312C14.564 13.0734 14.5346 13.1154 14.5048 13.157L8 8.5L14.593 13.0312Z"
        fill="#00FF15"
      />
      <path
        d="M14.5129 13.1456C14.4832 13.1873 14.4531 13.2287 14.4225 13.2698L8 8.5L14.5129 13.1456Z"
        fill="#00FF1A"
      />
      <path
        d="M14.4309 13.2586C14.4004 13.2997 14.3696 13.3406 14.3383 13.3812L8 8.5L14.4309 13.2586Z"
        fill="#00FF1E"
      />
      <path
        d="M14.3468 13.3701C14.3157 13.4107 14.2841 13.451 14.2522 13.491L8 8.5L14.3468 13.3701Z"
        fill="#00FF22"
      />
      <path
        d="M14.2609 13.4801C14.229 13.5202 14.1967 13.5599 14.1641 13.5994L8 8.5L14.2609 13.4801Z"
        fill="#00FF26"
      />
      <path
        d="M14.173 13.5886C14.1404 13.6281 14.1075 13.6673 14.0742 13.7062L8 8.5L14.173 13.5886Z"
        fill="#00FF2B"
      />
      <path
        d="M14.0832 13.6956C14.05 13.7345 14.0164 13.7731 13.9824 13.8114L8 8.5L14.0832 13.6956Z"
        fill="#00FF2F"
      />
      <path
        d="M13.9916 13.801C13.9577 13.8393 13.9234 13.8773 13.8888 13.915L8 8.5L13.9916 13.801Z"
        fill="#00FF33"
      />
      <path
        d="M13.8982 13.9047C13.8636 13.9425 13.8287 13.9799 13.7934 14.017L8 8.5L13.8982 13.9047Z"
        fill="#00FF37"
      />
      <path
        d="M13.803 14.0068C13.7678 14.044 13.7322 14.0808 13.6962 14.1172L8 8.5L13.803 14.0068Z"
        fill="#00FF3C"
      />
      <path
        d="M13.706 14.1073C13.6701 14.1438 13.6339 14.18 13.5973 14.2158L8 8.5L13.706 14.1073Z"
        fill="#00FF40"
      />
      <path
        d="M13.6073 14.206C13.5708 14.2419 13.5339 14.2774 13.4967 14.3126L8 8.5L13.6073 14.206Z"
        fill="#00FF44"
      />
      <path
        d="M13.5068 14.303C13.4697 14.3382 13.4322 14.3731 13.3944 14.4076L8 8.5L13.5068 14.303Z"
        fill="#00FF48"
      />
      <path
        d="M13.4047 14.3982C13.367 14.4328 13.3289 14.467 13.2905 14.5009L8 8.5L13.4047 14.3982Z"
        fill="#00FF4D"
      />
      <path
        d="M13.301 14.4916C13.2626 14.5256 13.2239 14.5591 13.185 14.5923L8 8.5L13.301 14.4916Z"
        fill="#00FF51"
      />
      <path
        d="M13.1956 14.5832C13.1567 14.6165 13.1174 14.6494 13.0778 14.6819L8 8.5L13.1956 14.5832Z"
        fill="#00FF55"
      />
      <path
        d="M13.0886 14.673C13.0491 14.7056 13.0093 14.7377 12.9692 14.7695L8 8.5L13.0886 14.673Z"
        fill="#00FF59"
      />
      <path
        d="M12.9801 14.7609C12.9401 14.7927 12.8997 14.8242 12.859 14.8553L8 8.5L12.9801 14.7609Z"
        fill="#00FF5E"
      />
      <path
        d="M12.8701 14.8468C12.8295 14.878 12.7886 14.9088 12.7474 14.9391L8 8.5L12.8701 14.8468Z"
        fill="#00FF62"
      />
      <path
        d="M12.7586 14.9309C12.7174 14.9613 12.676 14.9914 12.6342 15.021L8 8.5L12.7586 14.9309Z"
        fill="#00FF66"
      />
      <path
        d="M12.6456 15.0129C12.6039 15.0427 12.562 15.072 12.5197 15.1009L8 8.5L12.6456 15.0129Z"
        fill="#00FF6A"
      />
      <path
        d="M12.5312 15.093C12.4891 15.122 12.4466 15.1506 12.4038 15.1788L8 8.5L12.5312 15.093Z"
        fill="#00FF6F"
      />
      <path
        d="M12.4155 15.1711C12.3728 15.1993 12.3298 15.2272 12.2866 15.2546L8 8.5L12.4155 15.1711Z"
        fill="#00FF73"
      />
      <path
        d="M12.2984 15.2471C12.2552 15.2746 12.2118 15.3017 12.1681 15.3284L8 8.5L12.2984 15.2471Z"
        fill="#00FF77"
      />
      <path
        d="M12.18 15.3211C12.1363 15.3479 12.0924 15.3742 12.0483 15.4001L8 8.5L12.18 15.3211Z"
        fill="#00FF7B"
      />
      <path
        d="M12.0603 15.393C12.0162 15.419 11.9718 15.4446 11.9272 15.4697L8 8.5L12.0603 15.393Z"
        fill="#00FF80"
      />
      <path
        d="M11.9394 15.4628C11.8948 15.4881 11.85 15.5128 11.805 15.5372L8 8.5L11.9394 15.4628Z"
        fill="#00FF84"
      />
      <path
        d="M11.8173 15.5305C11.7723 15.555 11.7271 15.579 11.6816 15.6025L8 8.5L11.8173 15.5305Z"
        fill="#00FF88"
      />
      <path
        d="M11.694 15.5961C11.6486 15.6197 11.6029 15.6429 11.5571 15.6657L8 8.5L11.694 15.5961Z"
        fill="#00FF8C"
      />
      <path
        d="M11.5696 15.6595C11.5238 15.6823 11.4777 15.7047 11.4315 15.7267L8 8.5L11.5696 15.6595Z"
        fill="#00FF91"
      />
      <path
        d="M11.4441 15.7207C11.3979 15.7427 11.3515 15.7643 11.3048 15.7855L8 8.5L11.4441 15.7207Z"
        fill="#00FF95"
      />
      <path
        d="M11.3175 15.7797C11.271 15.8009 11.2242 15.8217 11.1772 15.842L8 8.5L11.3175 15.7797Z"
        fill="#00FF99"
      />
      <path
        d="M11.19 15.8365C11.143 15.8569 11.0959 15.8769 11.0486 15.8964L8 8.5L11.19 15.8365Z"
        fill="#00FF9D"
      />
      <path
        d="M11.0615 15.891C11.0142 15.9106 10.9667 15.9298 10.919 15.9484L8 8.5L11.0615 15.891Z"
        fill="#00FFA2"
      />
      <path
        d="M10.932 15.9433C10.8844 15.9621 10.8366 15.9804 10.7886 15.9983L8 8.5L10.932 15.9433Z"
        fill="#00FFA6"
      />
      <path
        d="M10.8017 15.9934C10.7537 16.0113 10.7056 16.0288 10.6573 16.0458L8 8.5L10.8017 15.9934Z"
        fill="#00FFAA"
      />
      <path
        d="M10.6705 16.0411C10.6222 16.0582 10.5738 16.0748 10.5252 16.091L8 8.5L10.6705 16.0411Z"
        fill="#00FFAE"
      />
      <path
        d="M10.5384 16.0866C10.4899 16.1028 10.4412 16.1186 10.3923 16.1339L8 8.5L10.5384 16.0866Z"
        fill="#00FFB3"
      />
      <path
        d="M10.4056 16.1297C10.3568 16.1451 10.3078 16.1601 10.2587 16.1745L8 8.5L10.4056 16.1297Z"
        fill="#00FFB7"
      />
      <path
        d="M10.2721 16.1706C10.223 16.1851 10.1738 16.1992 10.1244 16.2128L8 8.5L10.2721 16.1706Z"
        fill="#00FFBB"
      />
      <path
        d="M10.1379 16.209C10.0886 16.2227 10.0391 16.2359 9.98952 16.2487L8 8.5L10.1379 16.209Z"
        fill="#00FFBF"
      />
      <path
        d="M10.003 16.2452C9.95347 16.258 9.90379 16.2703 9.85398 16.2822L8 8.5L10.003 16.2452Z"
        fill="#00FFC4"
      />
      <path
        d="M9.86756 16.279C9.81778 16.2909 9.76789 16.3024 9.71788 16.3134L8 8.5L9.86756 16.279Z"
        fill="#00FFC8"
      />
      <path
        d="M9.73152 16.3104C9.68153 16.3214 9.63145 16.3321 9.58126 16.3422L8 8.5L9.73152 16.3104Z"
        fill="#00FFCC"
      />
      <path
        d="M9.59494 16.3394C9.54477 16.3496 9.49451 16.3593 9.44415 16.3686L8 8.5L9.59494 16.3394Z"
        fill="#00FFD0"
      />
      <path
        d="M9.45788 16.366C9.40754 16.3754 9.35712 16.3842 9.30661 16.3926L8 8.5L9.45788 16.366Z"
        fill="#00FFD5"
      />
      <path
        d="M9.32038 16.3903C9.26989 16.3987 9.21931 16.4067 9.16866 16.4142L8 8.5L9.32038 16.3903Z"
        fill="#00FFD9"
      />
      <path
        d="M9.18248 16.4121C9.13184 16.4197 9.08113 16.4268 9.03036 16.4334L8 8.5L9.18248 16.4121Z"
        fill="#00FFDD"
      />
      <path
        d="M9.04421 16.4316C8.99345 16.4382 8.94263 16.4444 8.89175 16.4501L8 8.5L9.04421 16.4316Z"
        fill="#00FFE1"
      />
      <path
        d="M8.90563 16.4486C8.85476 16.4544 8.80384 16.4597 8.75287 16.4645L8 8.5L8.90563 16.4486Z"
        fill="#00FFE6"
      />
      <path
        d="M8.76677 16.4632C8.71581 16.4681 8.6648 16.4725 8.61375 16.4764L8 8.5L8.76677 16.4632Z"
        fill="#00FFEA"
      />
      <path
        d="M8.62767 16.4753C8.57663 16.4794 8.52556 16.4829 8.47445 16.4859L8 8.5L8.62767 16.4753Z"
        fill="#00FFEE"
      />
      <path
        d="M8.48839 16.4851C8.43729 16.4882 8.38616 16.4908 8.33501 16.493L8 8.5L8.48839 16.4851Z"
        fill="#00FFF2"
      />
      <path
        d="M8.34895 16.4924C8.29781 16.4946 8.24664 16.4964 8.19546 16.4976L8 8.5L8.34895 16.4924Z"
        fill="#00FFF7"
      />
      <path
        d="M8.20941 16.4973C8.15824 16.4986 8.10705 16.4994 8.05585 16.4998L8 8.5L8.20941 16.4973Z"
        fill="#00FFFB"
      />
      <path
        d="M8.06982 16.4997C8.01862 16.5001 7.96742 16.5001 7.91623 16.4996L8 8.5L8.06982 16.4997Z"
        fill="#00FFFF"
      />
      <path
        d="M7.9302 16.4997C7.87901 16.4992 7.82782 16.4983 7.77664 16.4969L8.00002 8.5L7.9302 16.4997Z"
        fill="#00FBFF"
      />
      <path
        d="M7.7906 16.4973C7.73942 16.4959 7.68826 16.4941 7.63712 16.4918L8.00002 8.5L7.7906 16.4973Z"
        fill="#00F7FF"
      />
      <path
        d="M7.65105 16.4924C7.5999 16.4902 7.54878 16.4874 7.49768 16.4842L8 8.5L7.65105 16.4924Z"
        fill="#00F2FF"
      />
      <path
        d="M7.51163 16.4851C7.46053 16.482 7.40946 16.4783 7.35843 16.4742L8.00002 8.5L7.51163 16.4851Z"
        fill="#00EEFF"
      />
      <path
        d="M7.37232 16.4753C7.32128 16.4713 7.27028 16.4668 7.21933 16.4618L7.99999 8.5L7.37232 16.4753Z"
        fill="#00EAFF"
      />
      <path
        d="M7.23324 16.4632C7.18228 16.4583 7.13136 16.4529 7.08051 16.447L8 8.5L7.23324 16.4632Z"
        fill="#00E6FF"
      />
      <path
        d="M7.09438 16.4486C7.04351 16.4428 6.9927 16.4365 6.94196 16.4297L8.00001 8.5L7.09438 16.4486Z"
        fill="#00E1FF"
      />
      <path
        d="M6.95578 16.4316C6.90503 16.4249 6.85433 16.4177 6.80371 16.4101L7.99999 8.5L6.95578 16.4316Z"
        fill="#00DDFF"
      />
      <path
        d="M6.81754 16.4121C6.7669 16.4046 6.71634 16.3965 6.66586 16.388L8.00001 8.5L6.81754 16.4121Z"
        fill="#00D9FF"
      />
      <path
        d="M6.67961 16.3903C6.62912 16.3818 6.5787 16.3729 6.52838 16.3635L7.99999 8.5L6.67961 16.3903Z"
        fill="#00D5FF"
      />
      <path
        d="M6.54213 16.366C6.49179 16.3567 6.44154 16.3469 6.39139 16.3366L8.00001 8.5L6.54213 16.366Z"
        fill="#00D0FF"
      />
      <path
        d="M6.40505 16.3394C6.35489 16.3292 6.30482 16.3185 6.25485 16.3073L8 8.5L6.40505 16.3394Z"
        fill="#00CCFF"
      />
      <path
        d="M6.26849 16.3104C6.2185 16.2993 6.16863 16.2877 6.11887 16.2757L8 8.5L6.26849 16.3104Z"
        fill="#00C8FF"
      />
      <path
        d="M6.13245 16.279C6.08267 16.267 6.033 16.2546 5.98346 16.2417L8.00001 8.5L6.13245 16.279Z"
        fill="#00C4FF"
      />
      <path
        d="M5.99698 16.2452C5.94742 16.2324 5.89797 16.2191 5.84866 16.2053L8.00002 8.5L5.99698 16.2452Z"
        fill="#00BFFF"
      />
      <path
        d="M5.86211 16.209C5.81277 16.1954 5.76357 16.1812 5.71451 16.1666L8.00002 8.5L5.86211 16.209Z"
        fill="#00BBFF"
      />
      <path
        d="M5.72789 16.1706C5.6788 16.156 5.62985 16.141 5.58105 16.1255L8.00001 8.5L5.72789 16.1706Z"
        fill="#00B7FF"
      />
      <path
        d="M5.59436 16.1297C5.54553 16.1143 5.49686 16.0985 5.44833 16.0821L8.00001 8.5L5.59436 16.1297Z"
        fill="#00B3FF"
      />
      <path
        d="M5.46158 16.0866C5.41303 16.0703 5.36464 16.0536 5.31641 16.0365L8.00002 8.5L5.46158 16.0866Z"
        fill="#00AEFF"
      />
      <path
        d="M5.32955 16.0411C5.28129 16.024 5.2332 16.0065 5.18527 15.9885L8.00001 8.5L5.32955 16.0411Z"
        fill="#00AAFF"
      />
      <path
        d="M5.19836 15.9934C5.15041 15.9754 5.10262 15.9571 5.05502 15.9382L8.00002 8.5L5.19836 15.9934Z"
        fill="#00A6FF"
      />
      <path
        d="M5.06801 15.9433C5.02038 15.9246 4.97292 15.9054 4.92566 15.8857L8.00002 8.5L5.06801 15.9433Z"
        fill="#00A2FF"
      />
      <path
        d="M4.93854 15.891C4.89124 15.8714 4.84413 15.8514 4.79721 15.8309L8 8.5L4.93854 15.891Z"
        fill="#009DFF"
      />
      <path
        d="M4.81002 15.8365C4.76307 15.8161 4.71632 15.7952 4.66977 15.7739L8.00002 8.5L4.81002 15.8365Z"
        fill="#0099FF"
      />
      <path
        d="M4.68247 15.7797C4.63589 15.7585 4.58951 15.7368 4.54333 15.7147L8.00002 8.5L4.68247 15.7797Z"
        fill="#0095FF"
      />
      <path
        d="M4.55592 15.7207C4.50971 15.6986 4.46372 15.6762 4.41794 15.6532L8.00001 8.5L4.55592 15.7207Z"
        fill="#0091FF"
      />
      <path
        d="M4.43043 15.6595C4.38461 15.6366 4.33901 15.6133 4.29364 15.5896L8.00001 8.5L4.43043 15.6595Z"
        fill="#008CFF"
      />
      <path
        d="M4.30602 15.5961C4.26061 15.5724 4.21542 15.5484 4.17047 15.5239L8.00001 8.5L4.30602 15.5961Z"
        fill="#0088FF"
      />
      <path
        d="M4.18273 15.5305C4.13773 15.5061 4.09298 15.4812 4.04846 15.456L8 8.5L4.18273 15.5305Z"
        fill="#0084FF"
      />
      <path
        d="M4.06062 15.4628C4.01606 15.4376 3.97174 15.412 3.92767 15.3859L8 8.5L4.06062 15.4628Z"
        fill="#0080FF"
      />
      <path
        d="M3.93969 15.393C3.89557 15.367 3.85171 15.3406 3.80811 15.3138L7.99999 8.5L3.93969 15.393Z"
        fill="#007BFF"
      />
      <path
        d="M3.82003 15.3211C3.77638 15.2944 3.73298 15.2672 3.68985 15.2396L8.00002 8.5L3.82003 15.3211Z"
        fill="#0077FF"
      />
      <path
        d="M3.70161 15.2471C3.65843 15.2196 3.61552 15.1917 3.57288 15.1634L8.00001 8.5L3.70161 15.2471Z"
        fill="#0072FF"
      />
      <path
        d="M3.5845 15.1711C3.54181 15.1428 3.49939 15.1142 3.45724 15.0851L7.99999 8.5L3.5845 15.1711Z"
        fill="#006FFF"
      />
      <path
        d="M3.46875 15.093C3.42656 15.064 3.38465 15.0346 3.34302 15.0048L8 8.5L3.46875 15.093Z"
        fill="#006AFF"
      />
      <path
        d="M3.35437 15.0129C3.31269 14.9832 3.2713 14.9531 3.23019 14.9225L7.99999 8.5L3.35437 15.0129Z"
        fill="#0066FF"
      />
      <path
        d="M3.24141 14.9309C3.20026 14.9004 3.1594 14.8696 3.11884 14.8383L8 8.5L3.24141 14.9309Z"
        fill="#0062FF"
      />
      <path
        d="M3.12992 14.8468C3.0893 14.8157 3.04898 14.7841 3.00897 14.7522L8.00001 8.5L3.12992 14.8468Z"
        fill="#005EFF"
      />
      <path
        d="M3.01988 14.7609C2.97981 14.729 2.94005 14.6967 2.9006 14.6641L8 8.5L3.01988 14.7609Z"
        fill="#0059FF"
      />
      <path
        d="M2.91139 14.673C2.87189 14.6404 2.8327 14.6075 2.79382 14.5742L8.00002 8.5L2.91139 14.673Z"
        fill="#0055FF"
      />
      <path
        d="M2.80442 14.5832C2.76549 14.55 2.72688 14.5164 2.6886 14.4824L8.00001 8.5L2.80442 14.5832Z"
        fill="#0051FF"
      />
      <path
        d="M2.69904 14.4916C2.66069 14.4577 2.62268 14.4234 2.58499 14.3888L8 8.5L2.69904 14.4916Z"
        fill="#004DFF"
      />
      <path
        d="M2.5953 14.3982C2.55755 14.3636 2.52014 14.3287 2.48306 14.2934L8.00002 8.5L2.5953 14.3982Z"
        fill="#0048FF"
      />
      <path
        d="M2.49317 14.303C2.45603 14.2678 2.41924 14.2322 2.38278 14.1962L8.00001 8.5L2.49317 14.303Z"
        fill="#0044FF"
      />
      <path
        d="M2.39275 14.206C2.35623 14.1701 2.32006 14.1339 2.28424 14.0973L8.00002 8.5L2.39275 14.206Z"
        fill="#0040FF"
      />
      <path
        d="M2.294 14.1073C2.25812 14.0708 2.22259 14.0339 2.18741 13.9967L8.00001 8.5L2.294 14.1073Z"
        fill="#003CFF"
      />
      <path
        d="M2.19702 14.0068C2.16178 13.9697 2.1269 13.9322 2.09238 13.8944L8.00002 8.5L2.19702 14.0068Z"
        fill="#0037FF"
      />
      <path
        d="M2.10178 13.9047C2.0672 13.867 2.03297 13.8289 1.99911 13.7905L8 8.5L2.10178 13.9047Z"
        fill="#0033FF"
      />
      <path
        d="M2.00838 13.801C1.97445 13.7626 1.9409 13.7239 1.90771 13.685L8.00002 8.5L2.00838 13.801Z"
        fill="#002FFF"
      />
      <path
        d="M1.91677 13.6956C1.88352 13.6567 1.85064 13.6174 1.81815 13.5778L8.00001 8.5L1.91677 13.6956Z"
        fill="#002BFF"
      />
      <path
        d="M1.82702 13.5886C1.79445 13.5491 1.76227 13.5093 1.73047 13.4692L8.00002 8.5L1.82702 13.5886Z"
        fill="#0026FF"
      />
      <path
        d="M1.73914 13.4801C1.70726 13.4401 1.67578 13.3997 1.64468 13.359L8 8.5L1.73914 13.4801Z"
        fill="#0022FF"
      />
      <path
        d="M1.65318 13.3701C1.62201 13.3295 1.59123 13.2886 1.56085 13.2474L8 8.5L1.65318 13.3701Z"
        fill="#001EFF"
      />
      <path
        d="M1.56914 13.2586C1.53869 13.2174 1.50863 13.176 1.47897 13.1343L8 8.5L1.56914 13.2586Z"
        fill="#001AFF"
      />
      <path
        d="M1.48709 13.1456C1.45736 13.1039 1.42803 13.062 1.39911 13.0197L8.00002 8.5L1.48709 13.1456Z"
        fill="#0015FF"
      />
      <path
        d="M1.407 13.0312C1.378 12.9891 1.34941 12.9466 1.32123 12.9038L8.00001 8.5L1.407 13.0312Z"
        fill="#0011FF"
      />
      <path
        d="M1.32893 12.9155C1.30067 12.8728 1.27282 12.8298 1.24539 12.7866L8.00002 8.5L1.32893 12.9155Z"
        fill="#000DFF"
      />
      <path
        d="M1.25288 12.7984C1.22537 12.7552 1.19827 12.7118 1.1716 12.6681L8.00001 8.5L1.25288 12.7984Z"
        fill="#0009FF"
      />
      <path
        d="M1.17887 12.68C1.15212 12.6363 1.12579 12.5924 1.09988 12.5483L7.99999 8.5L1.17887 12.68Z"
        fill="#0004FF"
      />
      <path
        d="M1.10698 12.5603C1.081 12.5162 1.05544 12.4718 1.0303 12.4272L8.00001 8.5L1.10698 12.5603Z"
        fill="#0000FF"
      />
      <path
        d="M1.03717 12.4394C1.01196 12.3948 0.98718 12.35 0.96283 12.305L8.00002 8.5L1.03717 12.4394Z"
        fill="#0400FF"
      />
      <path
        d="M0.969478 12.3173C0.945049 12.2723 0.921052 12.2271 0.897491 12.1816L8.00001 8.5L0.969478 12.3173Z"
        fill="#0900FF"
      />
      <path
        d="M0.903927 12.194C0.880287 12.1486 0.857084 12.1029 0.83432 12.0571L8.00001 8.5L0.903927 12.194Z"
        fill="#0D00FF"
      />
      <path
        d="M0.840523 12.0696C0.817679 12.0238 0.795275 11.9777 0.773315 11.9315L8 8.5L0.840523 12.0696Z"
        fill="#1100FF"
      />
      <path
        d="M0.779325 11.9441C0.757285 11.8979 0.735688 11.8515 0.714539 11.8048L8.00001 8.5L0.779325 11.9441Z"
        fill="#1500FF"
      />
      <path
        d="M0.720306 11.8175C0.699075 11.771 0.678292 11.7242 0.657959 11.6772L8 8.5L0.720306 11.8175Z"
        fill="#1A00FF"
      />
      <path
        d="M0.663525 11.69C0.643111 11.643 0.623147 11.5959 0.603638 11.5486L8.00001 8.5L0.663525 11.69Z"
        fill="#1E00FF"
      />
      <path
        d="M0.608985 11.5615C0.589393 11.5142 0.570255 11.4667 0.551575 11.419L8.00002 8.5L0.608985 11.5615Z"
        fill="#2200FF"
      />
      <path
        d="M0.556655 11.432C0.537891 11.3844 0.519585 11.3366 0.50174 11.2886L8 8.5L0.556655 11.432Z"
        fill="#2600FF"
      />
      <path
        d="M0.506627 11.3017C0.488698 11.2537 0.471229 11.2056 0.454224 11.1573L8 8.5L0.506627 11.3017Z"
        fill="#2B00FF"
      />
      <path
        d="M0.458873 11.1705C0.441783 11.1222 0.425157 11.0738 0.408997 11.0252L8 8.5L0.458873 11.1705Z"
        fill="#2F00FF"
      />
      <path
        d="M0.413422 11.0384C0.397177 10.9899 0.381399 10.9412 0.366089 10.8923L8.00001 8.5L0.413422 11.0384Z"
        fill="#3300FF"
      />
      <path
        d="M0.370277 10.9056C0.354882 10.8568 0.339956 10.8078 0.3255 10.7587L8.00001 8.5L0.370277 10.9056Z"
        fill="#3700FF"
      />
      <path
        d="M0.329437 10.7721C0.314896 10.723 0.300827 10.6738 0.287231 10.6244L7.99999 8.5L0.329437 10.7721Z"
        fill="#3C00FF"
      />
      <path
        d="M0.290964 10.6379C0.277283 10.5886 0.264075 10.5391 0.251343 10.4895L8.00001 8.5L0.290964 10.6379Z"
        fill="#4000FF"
      />
      <path
        d="M0.25483 10.503C0.242011 10.4535 0.229669 10.4038 0.217804 10.354L8.00001 8.5L0.25483 10.503Z"
        fill="#4400FF"
      />
      <path
        d="M0.221034 10.3676C0.209082 10.3178 0.197609 10.2679 0.186615 10.2179L7.99999 8.5L0.221034 10.3676Z"
        fill="#4800FF"
      />
      <path
        d="M0.189638 10.2315C0.178557 10.1815 0.167956 10.1314 0.157837 10.0813L8.00001 8.5L0.189638 10.2315Z"
        fill="#4D00FF"
      />
      <path
        d="M0.160613 10.0949C0.150406 10.0448 0.140681 9.99451 0.131439 9.94415L8.00001 8.5L0.160613 10.0949Z"
        fill="#5100FF"
      />
      <path
        d="M0.13396 9.95788C0.12463 9.90754 0.115784 9.85712 0.107422 9.80661L8 8.5L0.13396 9.95788Z"
        fill="#5500FF"
      />
      <path
        d="M0.109709 9.82038C0.101259 9.76989 0.0932944 9.71931 0.0858154 9.66866L7.99999 8.5L0.109709 9.82038Z"
        fill="#5900FF"
      />
      <path
        d="M0.0878928 9.68248C0.0803254 9.63184 0.0732443 9.58113 0.0666504 9.53036L8.00002 8.5L0.0878928 9.68248Z"
        fill="#5E00FF"
      />
      <path
        d="M0.0684502 9.54421C0.0617677 9.49345 0.0555726 9.44263 0.0498657 9.39175L8.00001 8.5L0.0684502 9.54421Z"
        fill="#6200FF"
      />
      <path
        d="M0.0514433 9.40562C0.0456477 9.35476 0.0403405 9.30384 0.0355225 9.25287L8.00002 8.5L0.0514433 9.40562Z"
        fill="#6600FF"
      />
      <path
        d="M0.0368426 9.26677C0.0319356 9.2158 0.0275179 9.1648 0.0235901 9.11375L8.00001 8.5L0.0368426 9.26677Z"
        fill="#6A00FF"
      />
      <path
        d="M0.0246791 9.12767C0.0206622 9.07663 0.0171354 9.02556 0.0140991 8.97445L8.00002 8.5L0.0246791 9.12767Z"
        fill="#6F00FF"
      />
      <path
        d="M0.0149233 8.98839C0.0117978 8.93729 0.00916294 8.88616 0.00701904 8.835L8 8.5L0.0149233 8.98839Z"
        fill="#7300FF"
      />
      <path
        d="M0.00760652 8.84895C0.00537335 8.79781 0.00363122 8.74664 0.00238037 8.69546L7.99999 8.5L0.00760652 8.84895Z"
        fill="#7700FF"
      />
      <path
        d="M0.00276007 8.70942C0.0014199 8.65824 0.000571045 8.60705 0.000213623 8.55585L8.00002 8.5L0.00276007 8.70942Z"
        fill="#7B00FF"
      />
      <path
        d="M0.000335135 8.56982C-0.000111637 8.51862 -6.69591e-05 8.46742 0.000469162 8.41623L8.00003 8.5L0.000335135 8.56982Z"
        fill="#8000FF"
      />
      <path
        d="M0.000305176 8.43017C0.000751945 8.37898 0.00169014 8.32779 0.00311963 8.27661L8 8.49998L0.000305176 8.43017Z"
        fill="#8400FF"
      />
      <path
        d="M0.00274658 8.29057C0.00408675 8.23939 0.00591818 8.18823 0.00824062 8.13708L8.00001 8.49999L0.00274658 8.29057Z"
        fill="#8800FF"
      />
      <path
        d="M0.00762939 8.15105C0.00986257 8.0999 0.0125867 8.04878 0.0158013 7.99768L8.00002 8.5L0.00762939 8.15105Z"
        fill="#8C00FF"
      />
      <path
        d="M0.0149231 8.0116C0.0180486 7.9605 0.0216645 7.90943 0.0257704 7.8584L8 8.49999L0.0149231 8.0116Z"
        fill="#9100FF"
      />
      <path
        d="M0.0246582 7.87232C0.028675 7.82128 0.0331817 7.77028 0.0381777 7.71933L8 8.49999L0.0246582 7.87232Z"
        fill="#9500FF"
      />
      <path
        d="M0.0368347 7.73324C0.0417417 7.68228 0.0471378 7.63136 0.0530222 7.58051L8 8.5L0.0368347 7.73324Z"
        fill="#9900FF"
      />
      <path
        d="M0.0514221 7.59438C0.0572178 7.54351 0.0635015 7.4927 0.0702726 7.44196L8 8.50001L0.0514221 7.59438Z"
        fill="#9D00FF"
      />
      <path
        d="M0.0684509 7.45579C0.0751335 7.40503 0.0823031 7.35433 0.0899589 7.30371L8.00001 8.49999L0.0684509 7.45579Z"
        fill="#A200FF"
      />
      <path
        d="M0.0878906 7.31751C0.095458 7.26687 0.103511 7.21631 0.112049 7.16583L8.00002 8.49998L0.0878906 7.31751Z"
        fill="#A600FF"
      />
      <path
        d="M0.109711 7.17961C0.118161 7.12912 0.127095 7.0787 0.136513 7.02838L8 8.49999L0.109711 7.17961Z"
        fill="#AA00FF"
      />
      <path
        d="M0.133972 7.0421C0.143302 6.99176 0.153115 6.94151 0.163409 6.89136L8.00001 8.49998L0.133972 7.0421Z"
        fill="#AE00FF"
      />
      <path
        d="M0.160614 6.90505C0.170821 6.85489 0.181509 6.80482 0.192678 6.75485L8.00001 8.5L0.160614 6.90505Z"
        fill="#B300FF"
      />
      <path
        d="M0.189636 6.76849C0.200717 6.7185 0.212278 6.66863 0.224316 6.61887L8 8.5L0.189636 6.76849Z"
        fill="#B700FF"
      />
      <path
        d="M0.221039 6.63242C0.23299 6.58264 0.24542 6.53297 0.258325 6.48343L8 8.49998L0.221039 6.63242Z"
        fill="#BB00FF"
      />
      <path
        d="M0.254822 6.49695C0.26764 6.44739 0.280934 6.39794 0.294702 6.34863L8 8.49999L0.254822 6.49695Z"
        fill="#BF00FF"
      />
      <path
        d="M0.290955 6.36208C0.304636 6.31274 0.318791 6.26354 0.333418 6.21448L8 8.49998L0.290955 6.36208Z"
        fill="#C400FF"
      />
      <path
        d="M0.329437 6.22786C0.343978 6.17877 0.358989 6.12982 0.37447 6.08102L7.99999 8.49998L0.329437 6.22786Z"
        fill="#C800FF"
      />
      <path
        d="M0.37027 6.09433C0.385665 6.0455 0.401528 5.99683 0.417858 5.9483L8.00001 8.49998L0.37027 6.09433Z"
        fill="#CC00FF"
      />
      <path
        d="M0.413422 5.96155C0.429667 5.913 0.446377 5.86461 0.463551 5.81638L8.00001 8.49999L0.413422 5.96155Z"
        fill="#D000FF"
      />
      <path
        d="M0.458862 5.82955C0.475952 5.78129 0.493505 5.7332 0.511518 5.68527L7.99999 8.50001L0.458862 5.82955Z"
        fill="#D500FF"
      />
      <path
        d="M0.506622 5.69833C0.524552 5.65038 0.542941 5.60259 0.561788 5.55499L8 8.49999L0.506622 5.69833Z"
        fill="#D900FF"
      />
      <path
        d="M0.556671 5.56798C0.575435 5.52035 0.594655 5.47289 0.61433 5.42563L8.00001 8.49999L0.556671 5.56798Z"
        fill="#DD00FF"
      />
      <path
        d="M0.608978 5.43854C0.62857 5.39124 0.648616 5.34413 0.669113 5.29721L8.00001 8.5L0.608978 5.43854Z"
        fill="#E100FF"
      />
      <path
        d="M0.663513 5.30999C0.683928 5.26304 0.704793 5.21629 0.726105 5.16974L7.99999 8.49999L0.663513 5.30999Z"
        fill="#E600FF"
      />
      <path
        d="M0.720306 5.18244C0.741537 5.13586 0.763215 5.08948 0.785336 5.0433L8 8.49999L0.720306 5.18244Z"
        fill="#EA00FF"
      />
      <path
        d="M0.779327 5.05589C0.801368 5.00968 0.823852 4.96369 0.846776 4.91791L8.00001 8.49998L0.779327 5.05589Z"
        fill="#EE00FF"
      />
      <path
        d="M0.840546 4.9304C0.863389 4.88458 0.886673 4.83898 0.910392 4.79361L8.00002 8.49998L0.840546 4.9304Z"
        fill="#F200FF"
      />
      <path
        d="M0.903931 4.80602C0.927571 4.76061 0.951646 4.71542 0.976153 4.67047L8.00002 8.50001L0.903931 4.80602Z"
        fill="#F700FF"
      />
      <path
        d="M0.969482 4.68273C0.993911 4.63773 1.01877 4.59298 1.04406 4.54846L8.00002 8.5L0.969482 4.68273Z"
        fill="#FB00FF"
      />
      <path
        d="M1.03717 4.56062C1.06238 4.51606 1.08802 4.47174 1.11408 4.42767L8.00002 8.5L1.03717 4.56062Z"
        fill="#FF00FF"
      />
      <path
        d="M1.10696 4.43969C1.13295 4.39557 1.15936 4.35171 1.18618 4.30811L8 8.49999L1.10696 4.43969Z"
        fill="#FF00FB"
      />
      <path
        d="M1.17889 4.32C1.20564 4.27634 1.23281 4.23295 1.2604 4.18982L8.00002 8.49999L1.17889 4.32Z"
        fill="#FF00F7"
      />
      <path
        d="M1.25287 4.20158C1.28038 4.1584 1.3083 4.11549 1.33663 4.07285L8 8.49998L1.25287 4.20158Z"
        fill="#FF00F2"
      />
      <path
        d="M1.32892 4.0845C1.35718 4.04181 1.38584 3.99939 1.41491 3.95724L8 8.49999L1.32892 4.0845Z"
        fill="#FF00EE"
      />
      <path
        d="M1.40698 3.96875C1.43598 3.92656 1.46538 3.88465 1.49519 3.84302L7.99999 8.5L1.40698 3.96875Z"
        fill="#FF00EA"
      />
      <path
        d="M1.48709 3.85437C1.51682 3.81269 1.54695 3.7713 1.57748 3.73019L8.00002 8.49999L1.48709 3.85437Z"
        fill="#FF00E6"
      />
      <path
        d="M1.56915 3.74141C1.59961 3.70026 1.63045 3.6594 1.66169 3.61884L8.00001 8.5L1.56915 3.74141Z"
        fill="#FF00E1"
      />
      <path
        d="M1.65317 3.62989C1.68433 3.58927 1.71589 3.54895 1.74783 3.50894L7.99999 8.49998L1.65317 3.62989Z"
        fill="#FF00DD"
      />
      <path
        d="M1.73914 3.51988C1.77101 3.47981 1.80326 3.44005 1.8359 3.4006L8 8.5L1.73914 3.51988Z"
        fill="#FF00D9"
      />
      <path
        d="M1.827 3.41136C1.85956 3.37186 1.8925 3.33266 1.92582 3.29379L7.99999 8.49999L1.827 3.41136Z"
        fill="#FF00D5"
      />
      <path
        d="M1.91675 3.30439C1.95 3.26546 1.98362 3.22685 2.01761 3.18857L8 8.49998L1.91675 3.30439Z"
        fill="#FF00D0"
      />
      <path
        d="M2.00836 3.19904C2.04229 3.16069 2.07658 3.12268 2.11123 3.08499L8.00001 8.5L2.00836 3.19904Z"
        fill="#FF00CC"
      />
      <path
        d="M2.10178 3.09527C2.13636 3.05752 2.17131 3.02011 2.20662 2.98303L7.99999 8.49999L2.10178 3.09527Z"
        fill="#FF00C8"
      />
      <path
        d="M2.19702 2.99317C2.23226 2.95603 2.26786 2.91924 2.30381 2.88278L8.00002 8.50001L2.19702 2.99317Z"
        fill="#FF00C4"
      />
      <path
        d="M2.29401 2.89272C2.32989 2.8562 2.36612 2.82003 2.4027 2.78421L8.00001 8.49999L2.29401 2.89272Z"
        fill="#FF00BF"
      />
      <path
        d="M2.39273 2.794C2.42925 2.75812 2.46611 2.72259 2.5033 2.68741L8 8.50001L2.39273 2.794Z"
        fill="#FF00BB"
      />
      <path
        d="M2.49316 2.69699C2.5303 2.66175 2.56778 2.62687 2.60558 2.59235L8 8.49999L2.49316 2.69699Z"
        fill="#FF00B7"
      />
      <path
        d="M2.59528 2.60179C2.63302 2.5672 2.6711 2.53297 2.7095 2.49911L8 8.5L2.59528 2.60179Z"
        fill="#FF00B3"
      />
      <path
        d="M2.69904 2.50835C2.73738 2.47442 2.77605 2.44087 2.81504 2.40768L8 8.49999L2.69904 2.50835Z"
        fill="#FF00AE"
      />
      <path
        d="M2.80441 2.41674C2.84334 2.38349 2.88259 2.35061 2.92215 2.31812L8 8.49998L2.80441 2.41674Z"
        fill="#FF00AA"
      />
      <path
        d="M2.91138 2.32699C2.95088 2.29442 2.9907 2.26224 3.03082 2.23044L8 8.49999L2.91138 2.32699Z"
        fill="#FF00A6"
      />
      <path
        d="M3.0199 2.23914C3.05996 2.20726 3.10034 2.17578 3.14101 2.14468L8.00001 8.5L3.0199 2.23914Z"
        fill="#FF00A2"
      />
      <path
        d="M3.12991 2.15318C3.17053 2.12201 3.21145 2.09123 3.25265 2.06085L8.00001 8.5L3.12991 2.15318Z"
        fill="#FF009D"
      />
      <path
        d="M3.24142 2.06914C3.28258 2.03869 3.32403 2.00863 3.36576 1.97897L8.00001 8.5L3.24142 2.06914Z"
        fill="#FF0099"
      />
      <path
        d="M3.35437 1.98706C3.39605 1.95733 3.43801 1.928 3.48026 1.89908L7.99999 8.49999L3.35437 1.98706Z"
        fill="#FF0095"
      />
      <path
        d="M3.46875 1.90697C3.51094 1.87797 3.55341 1.84938 3.59615 1.8212L8 8.49998L3.46875 1.90697Z"
        fill="#FF0091"
      />
      <path
        d="M3.5845 1.8289C3.6272 1.80064 3.67016 1.77279 3.71338 1.74536L8 8.49998L3.5845 1.8289Z"
        fill="#FF008C"
      />
      <path
        d="M3.7016 1.75288C3.74478 1.72537 3.78822 1.69827 3.83192 1.6716L8 8.50001L3.7016 1.75288Z"
        fill="#FF0088"
      />
      <path
        d="M3.82001 1.67887C3.86366 1.65212 3.90757 1.62579 3.95173 1.59988L8 8.49999L3.82001 1.67887Z"
        fill="#FF0084"
      />
      <path
        d="M3.9397 1.60695C3.98381 1.58097 4.02817 1.55541 4.07277 1.53027L8 8.49998L3.9397 1.60695Z"
        fill="#FF0080"
      />
      <path
        d="M4.06061 1.53714C4.10517 1.51193 4.14997 1.48715 4.195 1.4628L8 8.49999L4.06061 1.53714Z"
        fill="#FF007B"
      />
      <path
        d="M4.18274 1.46945C4.22773 1.44502 4.27296 1.42102 4.31841 1.39746L8.00001 8.49998L4.18274 1.46945Z"
        fill="#FF0077"
      />
      <path
        d="M4.30603 1.4039C4.35144 1.38026 4.39708 1.35705 4.44294 1.33429L8.00002 8.49998L4.30603 1.4039Z"
        fill="#FF0073"
      />
      <path
        d="M4.43042 1.34052C4.47624 1.31768 4.52227 1.29528 4.56852 1.27332L8 8.5L4.43042 1.34052Z"
        fill="#FF006F"
      />
      <path
        d="M4.55591 1.27933C4.60212 1.25728 4.64854 1.23569 4.69516 1.21454L8 8.50001L4.55591 1.27933Z"
        fill="#FF006A"
      />
      <path
        d="M4.68246 1.22031C4.72905 1.19907 4.77584 1.17829 4.82283 1.15796L8.00001 8.5L4.68246 1.22031Z"
        fill="#FF0066"
      />
      <path
        d="M4.81003 1.16353C4.85698 1.14311 4.90412 1.12315 4.95146 1.10364L8.00002 8.50001L4.81003 1.16353Z"
        fill="#FF0062"
      />
      <path
        d="M4.93854 1.10895C4.98584 1.08936 5.03332 1.07022 5.08099 1.05154L8.00001 8.49999L4.93854 1.10895Z"
        fill="#FF005E"
      />
      <path
        d="M5.06799 1.05665C5.11563 1.03789 5.16344 1.01959 5.21143 1.00174L8 8.5L5.06799 1.05665Z"
        fill="#FF0059"
      />
      <path
        d="M5.19833 1.00663C5.24629 0.988698 5.29441 0.971229 5.3427 0.954224L7.99999 8.5L5.19833 1.00663Z"
        fill="#FF0055"
      />
      <path
        d="M5.32956 0.958873C5.37782 0.941783 5.42624 0.925157 5.47482 0.908997L8.00001 8.5L5.32956 0.958873Z"
        fill="#FF0051"
      />
      <path
        d="M5.46158 0.913392C5.51013 0.897147 5.55884 0.881368 5.60769 0.866058L8.00002 8.49998L5.46158 0.913392Z"
        fill="#FF004D"
      />
      <path
        d="M5.59436 0.870246C5.64319 0.854851 5.69216 0.839925 5.74128 0.82547L8.00001 8.49998L5.59436 0.870246Z"
        fill="#FF0048"
      />
      <path
        d="M5.72787 0.829437C5.77696 0.814896 5.82619 0.800827 5.87555 0.787231L8 8.49999L5.72787 0.829437Z"
        fill="#FF0044"
      />
      <path
        d="M5.86209 0.790965C5.91143 0.777283 5.96089 0.764075 6.01048 0.751343L8 8.50001L5.86209 0.790965Z"
        fill="#FF0040"
      />
      <path
        d="M5.99698 0.754799C6.04654 0.741981 6.09623 0.729638 6.14604 0.717773L8.00002 8.49998L5.99698 0.754799Z"
        fill="#FF003C"
      />
      <path
        d="M6.13245 0.721034C6.18223 0.709082 6.23212 0.697609 6.28213 0.686615L8.00001 8.49999L6.13245 0.721034Z"
        fill="#FF0037"
      />
      <path
        d="M6.26849 0.689638C6.31848 0.678557 6.36857 0.667956 6.41875 0.657837L8.00001 8.50001L6.26849 0.689638Z"
        fill="#FF0033"
      />
      <path
        d="M6.40506 0.660583C6.45523 0.650376 6.50549 0.640651 6.55585 0.631409L8 8.49998L6.40506 0.660583Z"
        fill="#FF002F"
      />
      <path
        d="M6.54211 0.63396C6.59245 0.62463 6.64288 0.615784 6.69339 0.607422L8 8.5L6.54211 0.63396Z"
        fill="#FF002B"
      />
      <path
        d="M6.67963 0.609709C6.73012 0.601259 6.7807 0.593294 6.83134 0.585815L8.00001 8.49999L6.67963 0.609709Z"
        fill="#FF0026"
      />
      <path
        d="M6.81754 0.587862C6.86817 0.580295 6.91888 0.573214 6.96965 0.56662L8.00001 8.49999L6.81754 0.587862Z"
        fill="#FF0022"
      />
      <path
        d="M6.95581 0.56842C7.00657 0.561737 7.05739 0.555542 7.10827 0.549835L8.00002 8.49998L6.95581 0.56842Z"
        fill="#FF001E"
      />
      <path
        d="M7.09439 0.551413C7.14526 0.545617 7.19618 0.54031 7.24715 0.535492L8.00002 8.49999L7.09439 0.551413Z"
        fill="#FF001A"
      />
      <path
        d="M7.23325 0.536812C7.28421 0.531905 7.33521 0.527487 7.38626 0.52356L8.00001 8.49998L7.23325 0.536812Z"
        fill="#FF0015"
      />
      <path
        d="M7.37234 0.524648C7.42338 0.520632 7.47446 0.517105 7.52557 0.514069L8.00002 8.49999L7.37234 0.524648Z"
        fill="#FF0011"
      />
      <path
        d="M7.51163 0.514923C7.56273 0.511798 7.61386 0.509163 7.66501 0.507019L8.00002 8.5L7.51163 0.514923Z"
        fill="#FF000D"
      />
      <path
        d="M7.65106 0.507606C7.70221 0.505373 7.75338 0.503631 7.80456 0.50238L8.00002 8.49999L7.65106 0.507606Z"
        fill="#FF0009"
      />
      <path
        d="M7.79059 0.50273C7.84177 0.501389 7.89296 0.500541 7.94415 0.500183L8 8.49999L7.79059 0.50273Z"
        fill="#FF0004"
      />
      <path
        d="M8.00003 16.5C12.4183 16.5 16 12.9182 16 8.49997C16 4.08169 12.4183 0.499969 8.00003 0.499969C3.58175 0.499969 3.05176e-05 4.08169 3.05176e-05 8.49997C3.05176e-05 12.9182 3.58175 16.5 8.00003 16.5Z"
        fill="url(#paint0_radial_1_38)"
      />
    </g>
    <defs>
      <radialGradient
        id="paint0_radial_1_38"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8.00003 8.49997) scale(8)"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </radialGradient>
      <clipPath id="clip0_1_38">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
