<template>
  <div class="va-imageeditor-main">
    <div class="va-imageeditor-container">
      <div
        class="show-size-container"
        :class="layerSelected.name ? 'layer-name' : ''"
        :style="{
          top: cropData.top + cropData.height / 2 + 'px',
          left: cropData.left + cropData.width / 2 + 'px',
        }"
      >
        <div class="size-info">
          <div v-if="layerSelected.name">
            {{ layerName }}
          </div>
          <div>
            {{ Math.round(croppedImageData.width) }} x
            {{ Math.round(croppedImageData.height) }}
          </div>
        </div>
      </div>
      <va-img v-if="src" id="imageEdit" :src="src" @load="setupCropper" />
    </div>
    <div class="edit-button-container">
      <div
        class="edit-button landscape-button tooltip"
        @click="changeAspectRatio(16 / 9)"
      >
        <img :src="landscapeIcon" />
        <div class="tooltiptext">16:9</div>
      </div>
      <div
        class="edit-button portrait-button tooltip"
        @click="changeAspectRatio(9 / 16)"
      >
        <img :src="portraitIcon" />
        <div class="tooltiptext">9:16</div>
      </div>
      <va-dropdown
        :dropdown-actions="availableItems"
        center
        up
        @DROPDOWN_SELECTED="onItemClick"
      >
        <div class="edit-button layers-button tooltip">
          <div class="tooltiptext">
            {{ $translate('icons.imageEditor.layer') }}
          </div>
          <img :src="layersIcon" />
        </div>
      </va-dropdown>
      <div class="edit-button zoom-button tooltip" @click="zoom(0.1)">
        <img src="@/assets/images/zoom-in-circle-icon.svg" />
        <div class="tooltiptext">
          {{ $translate('icons.imageEditor.zoom') }}
        </div>
      </div>
      <div class="edit-button zoom-button tooltip" @click="zoom(-0.1)">
        <img src="@/assets/images/zoom-out-circle-icon.svg" />
        <div class="tooltiptext">
          {{ $translate('icons.imageEditor.zoom') }}
        </div>
      </div>
      <div class="edit-button rotate-button tooltip" @click="rotate(-45)">
        <img src="@/assets/images/rotate-circle-icon.svg" />
        <div class="tooltiptext">
          {{ $translate('icons.imageEditor.rotate') }}
        </div>
      </div>
      <div class="edit-button rotate-button tooltip" @click="rotate(45)">
        <img src="@/assets/images/rotate-circle-icon-copy.svg" />
        <div class="tooltiptext">
          {{ $translate('icons.imageEditor.rotate') }}
        </div>
      </div>
      <div class="edit-button reset-button tooltip" @click="reset()">
        <img src="@/assets/images/reset-circle-icon.svg" />
        <div class="tooltiptext">
          {{ $translate('icons.imageEditor.reset') }}
        </div>
      </div>
      <div class="action-button cancel-button" @click="$emit('cancel')">
        <p>{{ $translate('media.editImage.cancel') }}</p>
      </div>
      <div class="action-button save-as-button" @click="saveAs">
        <p>{{ $translate('media.editImage.saveAs') }}</p>
      </div>
    </div>

    <va-modal
      v-if="showSaveModal"
      :type="'confirm'"
      @confirm="save"
      @close="showSaveModal = false"
    >
      <template #header>
        <h3>
          {{ $translate('media.editImage.saveAs') }}
        </h3>
      </template>
      <template #body>
        <div class="name-section">
          <va-input
            v-model="imageName"
            :label="saveModalBody"
            :maxlength="128"
            type="text"
            @keyup="imageLookup"
          >
            <template #append-inner>
              <span v-if="confirmType === 'replace'" class="warning-circle"
                >!</span
              >
            </template>
          </va-input>
          <span class="image-suffix">.{{ fileFormat }}</span>
        </div>
        <!-- resize section -->

        <div class="resize-section">
          <button class="resize-accordion" @click="openAccordion">
            <p class="resize-title">{{ imageSizeText }}</p>
            <svg-icon
              icon="chevron"
              :class="['accordion-icon', { opened: open }]"
            />
          </button>
          <div class="modal-input resize" :class="{ open }">
            <div class="size-open">
              <div>
                <div class="input-row">
                  <va-input
                    id="width"
                    v-model="width"
                    :label="`${$translate('imageEditor.width')}:`"
                    right-align
                    type="number"
                    @keyup="calcHeight"
                  >
                    <template #append>
                      {{ $translate('imageEditor.pixels') }}
                    </template>
                  </va-input>
                </div>
                <div class="input-row">
                  <va-input
                    id="height"
                    v-model="height"
                    :label="`${$translate('imageEditor.height')}:`"
                    type="number"
                    right-align
                    @keyup="calcWidth"
                  >
                    <template #append>
                      {{ $translate('imageEditor.pixels') }}
                    </template>
                  </va-input>
                </div>
              </div>
              <div class="lines">
                <div></div>
              </div>
              <div class="scale-info">
                {{ $translate('imageEditor.scale') }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="footer-buttons">
          <va-button color="secondary" @click="showSaveModal = false">
            {{ $translate('media.editImage.cancel') }}
          </va-button>
          <va-button
            data-testid="confirm-button"
            :color="confirmType === 'replace' ? 'danger' : 'primary'"
            :loading="uploadingImageStatusPending"
            @click="save"
          >
            {{ confirmText }}
          </va-button>
        </div>
      </template>
    </va-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Cropper from 'cropperjs/dist/cropper';
import FilenameHelper from '@/helpers/filename';
import VaDropdown from '@/components/framework/va-dropdown.vue';
import { withAsync } from '@/helpers/withAsync';
import { mediaLookup, mediaUpload } from '@/api/mediaApi';
import VaImg from '@/components/framework/va-img.vue';
import VaModal from '@/components/framework/va-modal.vue';
import VaButton from '@/components/framework/VaButton.vue';
import VaInput from '@/components/framework/vaInput/VaInput.vue';
import { apiStatus } from '@/api/constants/apiStatus';
import { apiStatusComputedFactory } from '@/api/helpers/apiStatusComputedFactory';
import landscapeIconUrl from '@/assets/images/landscape-circle-icon.svg';
import landscapeActiveIconUrl from '@/assets/images/landscape-active-circle-icon.svg';
import portraitIconUrl from '@/assets/images/portrait-circle-icon.svg';
import portraitActiveIconUrl from '@/assets/images/portrait-active-circle-icon.svg';
import layersIconUrl from '@/assets/images/crop-layer-circle.svg';
import layersActiveIconUrl from '@/assets/images/crop-layer-active-circle.svg';
import ErrorMessageHelper from '@/helpers/errorMessageHelper';
import SvgIcon from '@/components/icons/SvgIcon.vue';

const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

export default {
  components: {
    VaButton,
    VaModal,
    VaDropdown,
    VaImg,
    VaInput,
    SvgIcon,
  },
  props: {
    src: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  },
  emits: ['cancel', 'save'],
  data() {
    return {
      cropper: null,
      currentAspectRatio: NaN,
      showSaveModal: false,
      imageName: '',
      landscapeIcon: landscapeIconUrl,
      portraitIcon: portraitIconUrl,
      layersIcon: layersIconUrl,
      layerSelected: {},
      saveModalBody: '',
      width: null,
      height: null,
      maxSizePx: 15360,
      confirmText: '',
      confirmType: '',
      cropData: '',
      croppedImageData: '',
      layerName: '',
      open: false,
      uploadingImageStatus: IDLE,
    };
  },
  computed: {
    ...mapGetters({
      langData: 'langData',
      layers: 'layers',
    }),
    ...apiStatusComputedFactory('uploadingImageStatus'),
    availableItems() {
      return this.layers.map((layer) => {
        return { Label: layer.name, EmitAction: layer.code };
      });
    },
    imageSizeText() {
      return this.$translate('imageEditor.imageSize', this.width, this.height);
    },
    fileFormat() {
      const fileNameSplit = this.name.split('.');
      const extension = fileNameSplit[fileNameSplit.length - 1];
      return extension.toLowerCase();
    },
    fileMimeType() {
      switch (this.fileFormat) {
        case 'jpg':
        case 'jpeg':
          return 'image/jpeg';
        case 'png':
          return 'image/png';
        default:
          return null;
      }
    },
  },
  watch: {
    showSaveModal(val) {
      if (val) {
        this.cropper.disable();
      } else {
        this.cropper.enable();
      }
    },
  },
  mounted() {
    this.$store.dispatch('getLayers', {});
  },
  methods: {
    setupCropper() {
      if (this.src) {
        var image = document.querySelector('#imageEdit .va-img');
        image.addEventListener('cropmove', this.getCropperData);
        image.addEventListener('ready', this.getCropperData);
        image.addEventListener('zoom', this.getCropperData);
        window.addEventListener('resize', this.getCropperData);
        this.cropper = new Cropper(image, {
          dragMode: 'move',
          toggleDragModeOnDblclick: false,
          autoCropArea: 1,
        });
      }
    },
    changeAspectRatio(number) {
      if (number == this.currentAspectRatio) {
        this.cropper.setAspectRatio(NaN);
        this.currentAspectRatio = NaN;

        this.getCropperData();

        this.landscapeIcon = landscapeIconUrl;
        this.portraitIcon = portraitIconUrl;
      } else {
        this.layerSelected = {};
        this.getLayerAspectRatio(null);
        this.cropper.setAspectRatio(number);
        this.getCropperData();
        if (number == 16 / 9) {
          this.landscapeIcon = landscapeActiveIconUrl;
          this.portraitIcon = portraitIconUrl;
        } else if (number == 9 / 16) {
          this.landscapeIcon = landscapeIconUrl;
          this.portraitIcon = portraitActiveIconUrl;
        }
        this.currentAspectRatio = number;
      }
    },
    getLayerAspectRatio(data) {
      if (data) {
        this.cropper.setAspectRatio(data);
        this.layersIcon = layersActiveIconUrl;
      } else {
        this.cropper.setAspectRatio(NaN);
        this.layersIcon = layersIconUrl;
      }
      this.currentAspectRatio = NaN;
      this.getCropperData();
      this.landscapeIcon = landscapeIconUrl;
      this.portraitIcon = portraitIconUrl;
    },
    rotate(degree) {
      this.cropper.rotate(degree);
    },
    zoom(number) {
      this.cropper.zoom(number);
      this.getCropperData();
    },
    async imageLookup() {
      this.imageName = FilenameHelper.cleanUpImageName(this.imageName);
      const { response } = await withAsync(mediaLookup, {
        searchText: this.imageName + '.' + this.fileFormat,
        lookupBy: 'FileName',
      });

      if (response?.data) {
        if (response.data.found) {
          this.saveModalBody =
            this.langData[this.langData.selected].media.editImage.bodyReplace;
          this.confirmText =
            this.langData[this.langData.selected].media.editImage.replace;
          this.confirmType = 'replace';
        } else {
          this.saveModalBody =
            this.langData[this.langData.selected].media.editImage.bodySave;
          this.confirmText =
            this.langData[this.langData.selected].media.editImage.save;
          this.confirmType = 'save';
        }
      }
    },
    saveAs() {
      this.open = false;
      this.changeSize();
      const currentDate = new Date();
      const datetime =
        '_' +
        currentDate.getFullYear() +
        ('0' + (currentDate.getMonth() + 1)).slice(-2) +
        currentDate.getDate() +
        '_' +
        ('0' + currentDate.getHours()).slice(-2) +
        ('0' + currentDate.getMinutes()).slice(-2) +
        ('0' + currentDate.getSeconds()).slice(-2);
      this.imageName = this.name.split('.')[0] + datetime;
      this.showSaveModal = true;
      this.saveModalBody =
        this.langData[this.langData.selected].media.editImage.bodySave;
      this.confirmText =
        this.langData[this.langData.selected].media.editImage.save;
    },
    save() {
      // Validate size
      if (this.width > this.maxSizePx || this.height > this.maxSizePx) {
        this.$store.dispatch('addMessage', {
          message: {
            text: ErrorMessageHelper.renderErrorMessage(
              'MEDIA_FILE_EXCEEDS_MAX_PIXELSIZE',
            ),
            type: 'error',
          },
          time: 6000,
        });

        return;
      }

      // Proceed with saving
      this.uploadingImageStatus = PENDING;
      this.cropper
        .getCroppedCanvas({ width: this.width, height: this.height })
        .toBlob((blob) => {
          var fileObject = new File(
            [blob],
            this.imageName + '.' + this.fileFormat,
            {
              type: this.fileMimeType,
            },
          );
          var files = [];
          files.push(fileObject);
          this.uploadMedia(files);
        }, this.fileMimeType);
    },
    async uploadMedia(fileList) {
      this.uploadingImageStatus = PENDING;
      const { response } = await withAsync(
        mediaUpload,
        fileList,
        this.$translate,
        {
          allowOverwrite: true,
        },
      );

      if (response?.data?.Medias) {
        if (response.data.Medias.length > 0) {
          const media = response.data.Medias[0];
          if (!media.errorMessage) {
            this.$emit('save', media);
            this.uploadingImageStatus = SUCCESS;
          } else {
            this.uploadingImageStatus = ERROR;
          }
        }
      } else {
        this.uploadingImageStatus = ERROR;
      }
    },
    calcWidth() {
      let aspectRatio =
        this.croppedImageData.width / this.croppedImageData.height;
      this.width = Math.round(aspectRatio * this.height);
    },
    calcHeight() {
      let aspectRatio =
        this.croppedImageData.width / this.croppedImageData.height;
      this.height = Math.round(this.width / aspectRatio);
    },
    reset() {
      this.layerSelected = {};
      this.cropper.reset();
      this.getLayerAspectRatio(null);
    },
    getCropperData() {
      if (this.cropper) {
        this.$nextTick(() => {
          this.cropData = this.cropper.getCropBoxData();
          this.croppedImageData = this.cropper.getData();
        });
      }
    },
    onItemClick(action) {
      let index = this.layers.findIndex((layer) => layer.code === action);
      //deselect layer by clicking it again
      if (this.layerSelected === this.layers[index]) {
        this.layerSelected = {};
        this.getLayerAspectRatio(null);
      } else {
        this.layerSelected = this.layers[index];
        this.layerName = this.layerSelected.name;
        //pass layer aspect ratio to getLayerAspectRatio method
        this.getLayerAspectRatio(
          this.layerSelected.width / this.layerSelected.height,
        );
        this.cropper.setData({
          width: this.layerSelected.width,
          height: this.layerSelected.height,
        });
      }
    },
    openAccordion() {
      this.open = !this.open;
    },
    changeSize() {
      this.width = Math.round(this.croppedImageData.width);
      this.height = Math.round(this.croppedImageData.height);
    },
  },
};
</script>

<style lang="scss">
.va-imageeditor-main {
  width: 100%;
  height: 100%;

  .va-imageeditor-container {
    height: 100%;
  }

  .edit-button-container {
    z-index: 3;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: $color-bg-secondary;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .edit-button {
    width: 39px;
    height: 39px;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
  }

  .tooltip {
    position: relative;

    img {
      filter: brightness(100%);
      transition: filter 0.2s ease-out;
    }

    &:hover {
      img {
        filter: brightness(135%);
      }
    }
  }

  .tooltip .tooltiptext {
    box-sizing: border-box;
    visibility: hidden;
    width: auto;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 5px;
    background-color: $color-bg-tertiary;
    color: $color-text;
    text-align: left;
    padding: 0.5rem;
    border-radius: 6px;
    opacity: 0;
    transition: opacity 0.2s 0.5s;
    pointer-events: none;
    position: absolute;
    z-index: 1;

    .label {
      color: $color-text-secondary;
    }
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .edit-button.layers-button {
    margin-right: 25px;
  }

  .edit-button.reset-button {
    margin-left: 25px;
  }

  .action-button.cancel-button {
    margin-left: 25px;
  }

  .action-button {
    cursor: pointer;
    min-width: 100px;
    height: 35px;
    border-radius: 19.5px;
    border: solid 2px $color-action-border;
    background-color: transparent;
    margin-left: 5px;
    margin-right: 5px;
    transition: border-color 0.2s ease-out;

    p {
      font-family: inherit;
      font-size: 14px;
      font-weight: 500;
      line-height: 35px;
      text-align: center;
      color: $color-text-secondary;
      margin: 0;
      transition: color 0.2s ease-out;
    }

    &:hover {
      border-color: $color-action-border-hover;

      p {
        color: $color-text;
      }
    }
  }

  .action-button.save-as-button {
    background-color: $color-action-bg;
    border: none;
    height: 39px;
    transition: background-color 0.2s ease-out;

    &:hover {
      background-color: $color-action-bg-hover;
    }

    p {
      color: $color-text-inverted;
      line-height: 39px;
    }
  }

  .resize-section {
    margin-top: 20px;

    button {
      all: unset;
      cursor: pointer;
    }
  }

  .name-section {
    display: flex;
    align-items: end;
    gap: 0.5rem;

    .image-suffix {
      margin-bottom: 0.8rem;
    }

    .warning-circle {
      height: 17px;
      width: 17px;
      background-color: $color-fill-danger;
      border-radius: 50%;
      text-align: center;
      color: $color-text-inverted;
      line-height: 17px;
    }
  }

  .resize-accordion {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
  }

  .resize-title {
    display: inline;
  }

  .accordion-icon {
    cursor: pointer;
    color: $color-text-secondary;
    padding: 0 0.5rem;
    font-size: 0.6rem;

    &.opened {
      transform: rotate(180deg);
    }
  }

  $expansion-panel: 148px;

  .modal-input.resize {
    display: flex;
    height: 0;
    transition: 0.2s ease-out;
    overflow: hidden;
    padding-top: 1rem;

    &.open {
      height: $expansion-panel;
    }
  }

  .size-open {
    height: $expansion-panel;
    display: flex;
    align-items: center;
  }

  .input-row {
    margin-bottom: 10px;
    width: 8rem;
  }

  .scale-info {
    padding: 1rem 0 0 0.5rem;
  }

  .lines {
    margin-top: 14px;
    margin-left: 0.5rem;
    width: 20px;
    height: 72px;
    border-top: 2px solid $color-border;
    border-right: 2px solid $color-border;
    border-bottom: 2px solid $color-border;
  }
}

.show-size-container {
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 143px;
  height: 61px;
  background: $color-bg-overlay;
  border-radius: 40px;

  &.layer-name {
    height: 80px;
    min-width: 143px;
    width: fit-content;
    width: max-content;
    width: max-content;
    padding: 0 30px;
  }

  .size-info {
    position: relative;
    top: 50%;
    left: 50%;
    min-width: 120px;
    transform: translate(-50%, -50%);
    color: $color-text-secondary;
    font-family: inherit;
    font-size: 16px;
    line-height: 21px;
  }
}

// CROPPERJS
/* stylelint-disable-next-line no-descending-specificity */
.va-imageeditor-container img {
  max-width: 100%; /* This rule is very important, please do not ignore this! */
}

.cropper-container {
  direction: ltr;
  font-size: 0;
  line-height: 0;
  position: relative;
  touch-action: none;
  user-select: none;
}

/* stylelint-disable-next-line no-descending-specificity */
.cropper-container img {
  display: block;
  height: 100%;
  image-orientation: 0deg;
  max-height: none !important;
  max-width: none !important;
  min-height: 0 !important;
  min-width: 0 !important;
  width: 100%;
}

.cropper-wrap-box,
.cropper-canvas,
.cropper-drag-box,
.cropper-crop-box,
.cropper-modal {
  position: absolute;
  inset: 0;
}

.cropper-wrap-box,
.cropper-canvas {
  overflow: hidden;
}

.cropper-drag-box {
  background-color: $color-fill;
  opacity: 0;
}

.cropper-modal {
  background-color: #000;
  opacity: 0.5;
}

.cropper-view-box {
  display: block;
  height: 100%;
  outline: 1px solid #39f;
  overflow: hidden;
  width: 100%;
}

.cropper-dashed {
  border: 0 dashed #eee;
  display: block;
  opacity: 0.5;
  position: absolute;
}

.cropper-dashed.dashed-h {
  border-bottom-width: 1px;
  border-top-width: 1px;
  height: calc(100% / 3);
  left: 0;
  top: calc(100% / 3);
  width: 100%;
}

.cropper-dashed.dashed-v {
  border-left-width: 1px;
  border-right-width: 1px;
  height: 100%;
  left: calc(100% / 3);
  top: 0;
  width: calc(100% / 3);
}

.cropper-center {
  display: block;
  height: 0;
  left: 50%;
  opacity: 0.75;
  position: absolute;
  top: 50%;
  width: 0;
}

.cropper-center::before,
.cropper-center::after {
  background-color: #eee;
  content: ' ';
  display: block;
  position: absolute;
}

.cropper-center::before {
  height: 1px;
  left: -3px;
  top: 0;
  width: 7px;
}

.cropper-center::after {
  height: 7px;
  left: 0;
  top: -3px;
  width: 1px;
}

.cropper-face,
.cropper-line,
.cropper-point {
  display: block;
  height: 100%;
  opacity: 0.1;
  position: absolute;
  width: 100%;
}

.cropper-face {
  background-color: #fafafa;
  left: 0;
  top: 0;
}

.cropper-line {
  background-color: #39f;
}

.cropper-line.line-e {
  cursor: ew-resize;
  right: -3px;
  top: 0;
  width: 5px;
}

.cropper-line.line-n {
  cursor: ns-resize;
  height: 5px;
  left: 0;
  top: -3px;
}

.cropper-line.line-w {
  cursor: ew-resize;
  left: -3px;
  top: 0;
  width: 5px;
}

.cropper-line.line-s {
  bottom: -3px;
  cursor: ns-resize;
  height: 5px;
  left: 0;
}

.cropper-point {
  background-color: #39f;
  height: 5px;
  opacity: 0.75;
  width: 5px;
}

.cropper-point.point-e {
  cursor: ew-resize;
  margin-top: -3px;
  right: -3px;
  top: 50%;
}

.cropper-point.point-n {
  cursor: ns-resize;
  left: 50%;
  margin-left: -3px;
  top: -3px;
}

.cropper-point.point-w {
  cursor: ew-resize;
  left: -3px;
  margin-top: -3px;
  top: 50%;
}

.cropper-point.point-s {
  bottom: -3px;
  cursor: s-resize;
  left: 50%;
  margin-left: -3px;
}

.cropper-point.point-ne {
  cursor: nesw-resize;
  right: -3px;
  top: -3px;
}

.cropper-point.point-nw {
  cursor: nwse-resize;
  left: -3px;
  top: -3px;
}

.cropper-point.point-sw {
  bottom: -3px;
  cursor: nesw-resize;
  left: -3px;
}

.cropper-point.point-se {
  bottom: -3px;
  cursor: nwse-resize;
  height: 20px;
  opacity: 1;
  right: -3px;
  width: 20px;
}

@media (min-width: 768px) {
  .cropper-point.point-se {
    height: 15px;
    width: 15px;
  }
}

@media (min-width: 992px) {
  .cropper-point.point-se {
    height: 10px;
    width: 10px;
  }
}

@media (min-width: 1200px) {
  .cropper-point.point-se {
    height: 5px;
    opacity: 0.75;
    width: 5px;
  }
}

.cropper-point.point-se::before {
  background-color: #39f;
  bottom: -50%;
  content: ' ';
  display: block;
  height: 200%;
  opacity: 0;
  position: absolute;
  right: -50%;
  width: 200%;
}

.cropper-invisible {
  opacity: 0;
}

.cropper-bg {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC');
}

.cropper-hide {
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}

.cropper-hidden {
  display: none !important;
}

.cropper-move {
  cursor: move;
}

.cropper-crop {
  cursor: crosshair;
}

.cropper-disabled .cropper-drag-box,
.cropper-disabled .cropper-face,
.cropper-disabled .cropper-line,
.cropper-disabled .cropper-point {
  cursor: not-allowed;
}
</style>

<style lang="scss" scoped>
:deep(.dropdown) {
  ul.dropdown-content {
    min-width: 214px;
    max-height: 300px;
    text-align: center;
    overflow-y: scroll;
    margin-bottom: 1rem;
    background-clip: unset;
  }
}
</style>
