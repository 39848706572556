<template>
  <div class="va-field va-checkbox">
    <VaCheckbox
      :value="fieldModel.fieldName"
      :model-value="isChecked"
      :label="computedLabel"
      @update:model-value="select"
    />
  </div>
</template>

<script>
import VaCheckbox from '../../VaCheckbox.vue';

export default {
  components: {
    VaCheckbox,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    fieldModel: {
      type: Object,
      required: true,
    },
  },
  emits: ['updatedData'],
  data() {
    return {
      editMode: false,
    };
  },
  computed: {
    computedLabel: function () {
      if (
        this.fieldModel &&
        typeof this.fieldModel.label === 'object' &&
        this.fieldModel.label !== null
      ) {
        if (this.fieldModel.label[this.$getUserlanguage()]) {
          return this.fieldModel.label[this.$getUserlanguage()];
        }
        return this.fieldModel.label['en_US'];
      } else {
        return this.fieldModel.label;
      }
    },
    isChecked() {
      if (this.data[this.fieldModel.fieldName]) {
        return true;
      }
      return false;
    },
  },
  methods: {
    select: function (value) {
      // TODO Fix no-mutating-props issue
      // eslint-disable-next-line vue/no-mutating-props
      this.data[this.fieldModel.fieldName] = value;
      this.$emit('updatedData');
    },
  },
};
</script>

<style lang="scss" scoped>
.va-checkbox {
  position: relative;
  text-align: left;
}
</style>
