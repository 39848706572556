<template>
  <div class="va-breadcrumbs">
    <div class="va-breadcrumbs-container">
      <div
        v-for="(breadcrumb, index) in breadcrumbs"
        :key="index"
        class="breadcrumb"
      >
        <router-link
          v-if="breadcrumb.route"
          :data-testid="`breadcrumbs-${breadcrumb.name}`"
          :to="breadcrumb.route"
          class="clickable breadcrumb-name"
          :title="breadcrumb.name"
        >
          {{ breadcrumb.name }}
        </router-link>
        <span
          v-else
          :data-testid="`breadcrumbs-${breadcrumb.name}`"
          class="breadcrumb-name"
          :title="breadcrumb.name"
        >
          {{ breadcrumb.name }}
        </span>
        <svg-icon
          v-if="index !== breadcrumbs.length - 1"
          icon="chevron"
          class="chevron-icon"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/icons/SvgIcon.vue';
export default {
  components: {
    SvgIcon,
  },
  props: {
    breadcrumbs: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.va-breadcrumbs {
  width: auto;

  .va-breadcrumbs-container {
    display: inline-flex;
    align-items: center;
    height: 40px;
    border-radius: 19.5px;
    background-color: $color-bg-secondary;
    padding: 0 20px;
    color: $color-text-secondary;
    font-size: 0.875rem;
    line-height: 1.1;
  }

  .breadcrumb {
    display: inline-flex;
    align-items: center;
    cursor: default;
    white-space: pre;

    .breadcrumb-name {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .clickable {
      color: $color-text;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .chevron-icon {
    margin: 0 7.5px;
    transform: rotate(-90deg);
  }
}
</style>
