<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SP Icon / Status / Unpaired">
      <path
        d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
        fill="currentColor"
      />
      <path
        id="Vector (Stroke)"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.46967 4.46967C4.76256 4.17678 5.23744 4.17678 5.53033 4.46967L8.02993 6.96927C8.0302 6.96954 8.03046 6.9698 8.03073 6.97007L10.5298 9.46916C10.5302 9.4695 10.5305 9.46984 10.5308 9.47018L15.5303 14.4697C15.8232 14.7626 15.8232 15.2374 15.5303 15.5303C15.2374 15.8232 14.7626 15.8232 14.4697 15.5303L9.68934 10.75H8C7.58579 10.75 7.25 10.4142 7.25 10C7.25 9.58579 7.58579 9.25 8 9.25H8.18934L7.21302 8.27368C6.85532 8.33315 6.52232 8.50281 6.26256 8.76256C5.93437 9.09075 5.75 9.53587 5.75 10C5.75 10.4641 5.93437 10.9092 6.26256 11.2374C6.59075 11.5656 7.03587 11.75 7.5 11.75H8.5C8.91421 11.75 9.25 12.0858 9.25 12.5C9.25 12.9142 8.91421 13.25 8.5 13.25H7.5C6.63805 13.25 5.8114 12.9076 5.2019 12.2981C4.59241 11.6886 4.25 10.862 4.25 10C4.25 9.13805 4.59241 8.3114 5.2019 7.7019C5.44873 7.45507 5.73118 7.25204 6.03716 7.09782L4.46967 5.53033C4.17678 5.23744 4.17678 4.76256 4.46967 4.46967ZM10.75 7.5C10.75 7.08579 11.0858 6.75 11.5 6.75H12.5C13.1036 6.75 13.6952 6.91807 14.2086 7.23538C14.722 7.5527 15.137 8.00671 15.4069 8.54656C15.6768 9.0864 15.7911 9.69074 15.7369 10.2919C15.6827 10.893 15.4621 11.4671 15.1 11.95C14.8515 12.2814 14.3814 12.3485 14.05 12.1C13.7186 11.8515 13.6515 11.3814 13.9 11.05C14.095 10.79 14.2137 10.4808 14.2429 10.1572C14.2721 9.83348 14.2106 9.50806 14.0652 9.21738C13.9199 8.92669 13.6965 8.68222 13.42 8.51136C13.1436 8.3405 12.825 8.25 12.5 8.25H11.5C11.0858 8.25 10.75 7.91421 10.75 7.5Z"
        fill="#181E24"
      />
    </g>
  </svg>
</template>
