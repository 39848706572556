<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="va-messages" :class="position">
    <div class="message-list">
      <transition-group name="msg-list" tag="div" @leave="onLeave">
        <div
          v-for="msg in messages.fields"
          :id="'nmsg_id_' + msg.id"
          :key="msg.id"
          class="message"
          :class="msg.type"
          :data-testid="`message-${msg.id}`"
        >
          <div
            v-if="msg.progress"
            class="message-progress"
            :style="{ width: msg.progress + '%' }"
          />
          <div
            class="message-text"
            :data-testid="`message-text--${msg.type}`"
            v-html="msg.text"
          />
          <div
            v-if="msg.buttonText"
            class="message-btn"
            :class="msg.buttonStyle"
            @click="executeButtonClick(msg)"
          >
            {{ msg.buttonText }}
          </div>
          <div
            role="button"
            data-testid="messages--close-button"
            class="message-close"
            :title="$translate('icons.close')"
            @click="onClose(msg.id)"
          >
            <SvgIcon icon="close" />
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SvgIcon from '../icons/SvgIcon.vue';
export default {
  components: {
    SvgIcon,
  },
  props: {
    position: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      messages: 'messages',
    }),
  },
  methods: {
    onClose: function (id) {
      this.$store.dispatch('removeMessage', { id });
    },
    onLeave: function (el) {
      el.style.marginTop = 0 - el.offsetHeight + 'px';
    },
    executeButtonClick(msg) {
      var id = msg.id;
      this.$store.dispatch('removeMessage', { id });
      msg.buttonFunction(...msg.buttonParameters);
    },
  },
};
</script>

<style lang="scss" scoped>
@use '../../styles/animations';

.va-messages {
  position: fixed;
  bottom: 40px;
  left: 50%;
  z-index: 10000000000;
  backface-visibility: hidden;
  transform: translate3d(-50%, 0, 0);

  :deep(a) {
    text-decoration: none;
  }

  .message-list {
    background-color: transparent;

    .msg-list-enter {
      opacity: 0;
      transform: translate3d(0, -2rem, 0);
    }

    .msg-list-leave-to {
      opacity: 0;
      transform: translate3d(0, -2rem, 0);
    }

    .message {
      animation: down_in 2s ease-out;
      display: table;
      position: relative;
      transition: 1s ease-out;
      padding: 1.8rem 3.5rem 1.8rem 1.8rem;
      margin-top: 0.2rem;
      background-color: $color-bg-secondary;

      .message-text {
        position: relative;
        display: inline-block;
      }

      .message-btn {
        display: inline-block;
        text-align: center;
        cursor: pointer;
        margin: -1rem 0 -1rem 1.5rem;
        border-radius: 10px;
        border: solid 2px $color-action-border;
        padding: 5px;
        background-color: transparent;
      }

      .message-btn.link {
        border: 0;
        margin: 0 0 0 0.5rem;
        padding: 0;
      }

      .message-close {
        position: absolute;
        right: 1.5rem;
        font-size: 0.7rem;
        top: 50%;
        margin-top: -0.35rem;
        cursor: pointer;
        display: inline-block;
      }

      .message-progress {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
      }

      &.success {
        border-left: 4px solid $color-fill-success;

        :deep(a),
        .message-btn.link {
          color: $color-fill-success;
        }

        .message-progress {
          transition-property: width, background-color;
          transition-duration: 2s, 0.5s;
          transition-delay: 2s, 0s;
          width: 0 !important;
          background-color: $color-fill-success;
        }
      }

      &.warning {
        border-left: 4px solid $color-fill-warning;

        :deep(a),
        .message-btn.link {
          color: $color-fill-warning;
        }
      }

      &.error {
        border-left: 4px solid $color-fill-danger;

        :deep(a),
        .message-btn.link {
          color: $color-fill-danger;
        }

        .message-progress {
          transition-property: width, background-color;
          transition-duration: 2s, 0.5s;
          transition-delay: 2s, 0s;
          width: 0 !important;
          background-color: $color-fill-danger;
        }
      }

      &.info {
        border-left: 4px solid $color-fill-info;

        :deep(a),
        .message-btn.link {
          color: $color-fill-info;
        }

        .message-progress {
          transition: width 0.1s;
          background-color: $color-fill-info;
        }
      }
    }
  }
}
</style>
