<template>
  <div class="preview-modal">
    <div name="modal">
      <div class="modal-mask">
        <div class="modal-top">
          <div class="back-button" @click="$emit('close')">
            {{ $translate('preview.closePreview') }}
          </div>
          <div class="item-name" :class="{ orientation: isWebPage || isSign }">
            <p>{{ media.displayName }}</p>
            <div v-if="isWebPage || isSign" class="orientation-select">
              <span
                :class="{ active: orientation == '16:9' }"
                @click="changeOrientation('16:9')"
                >16:9</span
              ><span
                :class="{ active: orientation == '9:16' }"
                @click="changeOrientation('9:16')"
                >9:16</span
              >
            </div>
          </div>
          <div
            v-if="mediaActions && mediaActions.length > 0"
            class="item-icon dropdown-container"
          >
            <va-dropdown
              v-if="isImageEditMode == false"
              :dropdown-actions="mediaActions"
              @DROPDOWN_SELECTED="onDropdownItemClick($event)"
            >
              <svg-icon
                icon="options"
                :title="$translate('broadcast.headings.options')"
              />
            </va-dropdown>
          </div>
        </div>
        <div class="modal-wrapper">
          <div class="modal-container">
            <div
              v-show="!isImage && !isLoaded && timeout != 'loaded'"
              class="modal-media"
            >
              <div class="loading-animation" />
            </div>
            <div
              v-show="isLoaded"
              v-if="!isWebPage && !isSign && !isImage && isVideo"
              class="modal-media"
            >
              <video
                class="video-preview"
                :src="previewUrl"
                controls
                autoplay
                @canplay="isLoaded = true"
              />
            </div>
            <div v-if="isImage" class="modal-media">
              <va-img v-if="!isImageEditMode" :src="previewUrl" />
              <va-imageeditor
                v-if="isImageEditMode"
                :src="previewUrl"
                :name="media.displayName"
                @cancel="isImageEditMode = false"
                @save="onCropped"
              />
            </div>
            <div
              v-if="isSign || isWebPage"
              class="modal-media sign"
              :class="timeout"
            >
              <iframe
                v-if="previewUrl"
                :src="previewUrl"
                @load="loadHtmlView"
                @error="onHtmlPageTimeout"
              />
            </div>
            <div
              v-if="timeout == 'loaded' && isWebPage"
              class="modal-media weberror"
            >
              <h1>{{ $translate('preview.notPossible') }}</h1>
              <img src="@/assets/images/sad-browser.svg" alt="" />
              <p class="page-error-info">
                {{ $translate('preview.cantDisplay') }}
              </p>
              <p class="button" @click="windowPreview">
                {{ $translate('preview.newWindowButton') }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <media-delete-modal
        v-if="showDeleteModal"
        :media="[item.mediaCode]"
        @deleted="$emit('addedMedia')"
        @close="showDeleteModal = false"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import playerServices from '@/services/player';
import { userComputed } from '@/services/auth.service';
import VaDropdown from '../framework/va-dropdown.vue';
import VaImageeditor from '../framework/va-imageeditor.vue';
import VaImg from '@/components/framework/va-img.vue';
import SvgIcon from '@/components/icons/SvgIcon.vue';
import MediaDeleteModal from '@/views/media/components/MediaDeleteModal.vue';
import { getMetadata } from '@/api/mediaApi';
import { withAsync } from '@/helpers/withAsync';

export default {
  components: {
    VaDropdown,
    VaImageeditor,
    VaImg,
    SvgIcon,
    MediaDeleteModal,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    playerCode: {
      type: String,
      default: null,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    hasDeleteOption: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['addedMedia', 'close'],
  data() {
    return {
      isLoaded: false,
      media: { displayName: '' },
      isImage: false,
      isImageEditMode: false,
      isVideo: false,
      isSign: false,
      isWebPage: false,
      previewUrl: null,
      previewBlob: null,
      orientation: '16:9',
      timeout: '',
      showDeleteModal: false,
    };
  },
  computed: {
    ...mapGetters({
      langData: 'langData',
      userRole: 'userRole',
    }),
    ...userComputed,
    mediaActions() {
      let returnItemActions = [];

      if (this.isSign) {
        returnItemActions = [
          {
            Label:
              this.langData[this.langData.selected].media.itemActions.editSign,
            EmitAction: 'TEMPLATE',
          },
        ];
      } else if (this.isImage && this.item.allow?.edit) {
        returnItemActions = [
          {
            Label:
              this.langData[this.langData.selected].media.itemActions.editImage,
            EmitAction: 'EDIT',
          },
        ];
      }

      if (this.hasDeleteOption) {
        returnItemActions.push({
          Label: this.langData[this.langData.selected].media.itemActions.delete,
          EmitAction: 'DELETE',
          IsDanger: true,
        });
      }

      if (!this.item.allow?.edit) {
        var idxTag = returnItemActions.findIndex(
          (a) => a.EmitAction === 'TEMPLATE',
        );
        if (idxTag !== -1) {
          returnItemActions.splice(idxTag, 1);
        }
      }

      if (!this.item.allow?.delete) {
        var idxDelete = returnItemActions.findIndex(
          (a) => a.EmitAction === 'DELETE',
        );
        if (idxDelete !== -1) {
          returnItemActions.splice(idxDelete, 1);
        }
      }

      return returnItemActions;
    },
  },
  created() {
    if (this.playerCode != null) {
      playerServices
        .getPlayerSettings(this.playerCode)
        .then((settings) => {
          this.orientation = settings.format.aspect;
        })
        .catch((error) => {
          this.$store.dispatch('addMessage', {
            message: {
              text: this.$translate('media.error.orientation'),
              buttonFunction: this.onAlertError,
              buttonParameters: error,
              buttonStyle: 'link',
              buttonText: this.$translate('generic.showMore'),
              type: 'error',
            },
            time: 10000,
          });
          console.error(error);
        });
    }
    setTimeout(this.onHtmlPageTimeout, 3000);
    this.getMetadata(this.item.mediaCode);
    if (this.editMode != null) {
      this.isImageEditMode = this.editMode;
    }
  },
  methods: {
    async getMetadata(mediaCode) {
      const { response } = await withAsync(getMetadata, mediaCode);

      if (response?.data) {
        const metadata = response.data;
        this.media = metadata;
        this.isVideo = 'Video' == this.media.mediaTypes;
        this.isImage = 'Photo' == this.media.mediaTypes;
        this.isSign = 'HtmlAd' == this.media.mediaTypes;
        this.isWebPage = 'WebPage' == this.media.mediaTypes;
        if (this.isWebPage) {
          this.previewUrl = this.media.displayName;
        } else {
          this.previewUrl = this.media.previewUrl;

          if (this.user?.access_token) {
            this.previewUrl += `?access_token=${this.user?.access_token}`;
          }
        }
      }
    },
    onCropped(croppedImage) {
      this.previewUrl = croppedImage.previewUrl;
      this.media.displayName = croppedImage.displayName;
      this.isImageEditMode = false;
      this.$emit('addedMedia', croppedImage);
    },
    onDropdownItemClick(e) {
      switch (e) {
        case 'DELETE':
          this.showDeleteModal = true;
          break;
        case 'TEMPLATE':
          this.$store.dispatch('setFormData', {});
          this.$router.push({
            name: 'TemplateFormEdit',
            params: {
              mediaCode: this.item.mediaCode,
              datacode: 'edit',
              target: 'media',
            },
          });
          break;
        case 'EDIT':
          this.isImageEditMode = true;
          break;
      }
    },
    onHtmlPageTimeout() {
      this.timeout = 'loaded';
    },
    onAlertError(err) {
      this.$store.dispatch('addMessage', {
        message: { text: err.message, type: 'info' },
        time: 10000,
      });
    },
    windowPreview() {
      if (this.timeout == 'loaded' && this.isWebPage) {
        window.open(this.previewUrl, '_blank');
      }
    },
    loadHtmlView(e) {
      clearTimeout(this.timeout);
      if (this.isSign || this.isWebPage) {
        this.setOrientation(e.srcElement);
      }
    },
    changeOrientation(orient) {
      this.orientation = orient;
      this.setOrientation(document.querySelector('iframe'));
    },
    setOrientation(iframeElem) {
      iframeElem.style.width = '100%';
      iframeElem.style.height = '100%';
      var scale, scaleX, scaleY;
      if (this.orientation == '16:9') {
        scaleX =
          window.getComputedStyle(iframeElem).width.replace('px', '') / 1920;
        scaleY =
          window.getComputedStyle(iframeElem).height.replace('px', '') / 1080;
        if (scaleY > scaleX) {
          scale = scaleX;
          iframeElem.style.height = 1080 * scale + 'px';
        } else {
          scale = scaleY;
          iframeElem.style.width = 1920 * scale + 'px';
        }
      } else if (this.orientation == '9:16') {
        scaleX =
          window.getComputedStyle(iframeElem).width.replace('px', '') / 1080;
        scaleY =
          window.getComputedStyle(iframeElem).height.replace('px', '') / 1920;
        if (scaleY > scaleX) {
          scale = scaleX;
          iframeElem.style.height = 1920 * scale + 'px';
        } else {
          scale = scaleY;
          iframeElem.style.width = 1080 * scale + 'px';
        }
      }
      iframeElem.contentWindow.postMessage(
        { orientation: this.orientation, scale: scale },
        '*',
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-modal {
  .modal-mask {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-bg;
    display: table;
    transition: opacity 0.2s ease;
  }

  .modal-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
  }

  .modal-top {
    background-color: $color-bg-secondary;
    height: 80px;
    position: fixed;
    top: 0;
    width: 100%;
    text-align: center;
    z-index: 1;

    .preview-dropdown,
    .back-button i {
      width: auto;
      height: auto;
      margin-top: 0;
      margin-bottom: 0;
    }

    .item-name > p {
      font-size: 16px;
      font-weight: 500;
      color: $color-text-secondary;
      text-align: center;
      margin-top: 0;
    }
  }

  .modal-top .item-name {
    display: inline-block;
    font-family: inherit;
    font-size: 16px;
    text-align: center;
    padding: 10px;
    line-height: 60px;

    &.orientation {
      line-height: 30px;
    }
  }

  .modal-top .back-button {
    display: block;
    color: $color-text-secondary;
    position: absolute;
    left: 40px;
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    margin: 20px 10px;
    padding: 0;
    transition: color 0.2s ease-out;

    &::before {
      position: absolute;
      content: '';
      width: 10px;
      height: 10px;
      margin-left: -20px;
      top: 13px;
      transform: rotate(-45deg);
      border-left: solid 2px $color-fill-secondary;
      border-top: solid 2px $color-fill-secondary;
      transition: border-color 0.2s ease-out;
    }

    &:hover {
      color: $color-text;

      &::before {
        border-color: $color-fill;
      }
    }
  }

  .fa.fa-angle-left {
    margin-top: 0;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .item-name {
    left: 0;
    right: 0;
    margin: auto;
    max-width: calc(100% - 400px);
    position: absolute;
    color: $color-text-secondary;

    .orientation-select {
      > span {
        margin: 0 10px;
        cursor: pointer;

        &:hover,
        &.active {
          color: $color-text;
        }
      }
    }
  }

  .modal-container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background-color: $color-bg;
    transition: all 0.2s ease;
    border-radius: 5px;
  }

  .modal-media {
    position: absolute;
    max-width: 80%;
    max-height: calc(90% - 160px);
    margin: auto;
    inset: 0;
    z-index: 1;
    text-align: center;

    .button {
      color: $color-text-secondary;
      height: 20px;
      padding: 10px;
      line-height: 20px;
      font-family: inherit;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      border: none;
      border-radius: 19.5px;
      background-color: $color-action-bg-secondary;
      margin: auto;
      display: inline-block;
      margin-top: 1rem;

      &:hover {
        color: $color-text;
      }
    }

    video {
      position: absolute;
      max-width: 100%;
      max-height: 100%;
      margin: auto;
      inset: 0;
    }

    &.sign {
      width: 1920px;
      height: 1080px;
      pointer-events: none;

      iframe {
        width: 100%;
        height: 100%;
        background-color: $color-bg;
        pointer-events: none;
      }

      &.loaded {
        iframe {
          background-color: transparent;
        }
      }
    }

    &.weberror {
      z-index: 0;
      text-align: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      > h1 {
        font-size: 26px;
        font-weight: bold;
        color: $color-text-secondary;
        margin-bottom: 1rem;
      }

      .page-error-info {
        font-size: 20px;
        color: $color-text-secondary;
        margin: 2rem 0 4rem;
        width: 40%;
        margin-left: 30%;
      }
    }

    :deep(.va-img-container) {
      width: 100%;
      height: 100%;

      .loader {
        width: 200px;
        height: 200px;
      }
    }
  }

  /*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    transform: scale(1.1);
  }

  .loading-animation {
    z-index: -1;
    position: absolute;
    top: calc(50% - 100px);
    left: calc(50% - 100px);
    border: 0.5rem solid $color-fill-inactive;
    border-top: 0.5rem solid $color-fill-active;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    animation: loading-spin 2s linear infinite;
    box-sizing: border-box;
  }

  @keyframes loading-spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* Dropdown */
  .item-icon.dropdown-container {
    float: right;
    margin-right: 40px;
    margin-top: 0;
    height: 80px;
    display: flex;
    align-items: center;
  }
}
</style>
