<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M14 2.66668H9.33333M6.66667 2.66668H2M14 8.00001H8M5.33333 8.00001H2M14 13.3333H10.6667M8 13.3333H2M9.33333 1.33334V4.00001M5.33333 6.66668V9.33334M10.6667 12V14.6667"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
