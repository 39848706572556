import axios, {
  type AxiosResponse,
  type AxiosInstance,
  type AxiosError,
  type InternalAxiosRequestConfig,
  type AxiosRequestConfig,
} from 'axios';
import Env from '@/utils/env';
import {
  userComputed,
  login,
  loginWithProvider,
} from '@/services/auth.service';
import store from '@/store';
import ErrorMessageHelper from '../helpers/errorMessageHelper.js';
import { waitForCondition } from '@/helpers/waitForCondition';
import { type ResponseDataType } from '@/types/ResponseDataType';

const BASE_URL_SIGNAGE_BACKEND =
  Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') + '/api/';

// Default config for the axios instance
const axiosParams = {
  baseURL: BASE_URL_SIGNAGE_BACKEND,
};
// Create axios instance with default params
const axiosInstance = axios.create(axiosParams);

const authInterceptor = async (
  config: InternalAxiosRequestConfig & { switchingClient?: boolean },
) => {
  // Wait until the condition is fulfilled
  await waitForCondition(
    () => store.getters.isClientLoaded || config.switchingClient,
  );

  const authToken = userComputed.user.get()?.access_token;
  if (authToken && [BASE_URL_SIGNAGE_BACKEND].includes(config.baseURL ?? '')) {
    config.headers['Authorization'] = `Bearer ${authToken}`;
  }
  return config;
};

const errorInterceptor = (error: AxiosError) => {
  // check if it's a server error
  if (!error.response) {
    return Promise.reject(error);
  }

  const provider = localStorage.getItem('provider');

  // all the error responses
  switch (error.response.status) {
    case 401:
      if (provider) {
        loginWithProvider(provider);
      } else {
        login();
      }
      break;
    case 400:
    case 403:
    case 500:
      store.dispatch('addMessage', {
        message: {
          text: ErrorMessageHelper.renderErrorMessage(error.response),
          type: 'error',
        },
        time: 10000,
      });
      break;
    default:
      console.error(error.response.status, error.message);
  }
  return Promise.reject(error);
};

const responseInterceptor = (response: AxiosResponse) => {
  if (response.data.success && !response.data.success.ok) {
    store.dispatch('addMessage', {
      message: {
        text: ErrorMessageHelper.renderErrorMessage(response),
        type: 'error',
      },
      time: 10000,
    });
  }

  return response;
};

axiosInstance.interceptors.request.use(authInterceptor);
axiosInstance.interceptors.response.use(responseInterceptor, errorInterceptor);

const didAbort = (error: unknown) => axios.isCancel(error);
const getCancelSource = () => axios.CancelToken.source();

// Main api function
const api = (axios: AxiosInstance) => {
  const withAbort =
    <T>(
      fn:
        | typeof axios.get<T>
        | typeof axios.post<T>
        | typeof axios.put<T>
        | typeof axios.delete<T>,
    ) =>
    async (...args: [...unknown[], Config]) => {
      const originalConfig = <Config>args[args.length - 1];
      // Extract abort property from the config
      const { abort, ...config } = originalConfig;
      // Create cancel token and abort method only if abort
      // function was passed
      if (typeof abort === 'function') {
        const { cancel, token } = getCancelSource();
        config.cancelToken = token;
        abort(cancel);
      }
      try {
        // Spread all arguments from args besides the original config,
        // and pass the rest of the config without abort property
        // @ts-expect-error - TS can't handle the spread operator
        return await fn(...args.slice(0, args.length - 1), config);
      } catch (error) {
        // Add "aborted" property to the error if the request was cancelled
        throw didAbort(error) ? { ...error, aborted: true } : error;
      }
    };

  // Wrapper functions around axios
  return {
    get: <T>(url: string, config: Config = {}) =>
      withAbort<ResponseDataType<T>>(axios.get)(url, config),
    post: <T>(url: string, body?: unknown, config: Config = {}) =>
      withAbort<ResponseDataType<T>>(axios.post)(url, body, config),
    put: <T>(url: string, body?: unknown, config: Config = {}) =>
      withAbort<ResponseDataType<T>>(axios.put)(url, body, config),
    delete: <T>(url: string, config: Config = {}) =>
      withAbort<ResponseDataType<T>>(axios.delete)(url, config),
  };
};
// Initialize the api function and pass axiosInstance to it
export default api(axiosInstance);

type Config = AxiosRequestConfig & { abort?: (a: () => void) => void };
