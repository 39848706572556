<template>
  <div class="field" :class="{ 'view-mode': isViewMode, empty: isEmpty }">
    <label>
      <slot name="field-label" />
    </label>
    <div class="field-element" :class="{ 'view-mode': isViewMode }">
      <slot name="field-data" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isViewMode: {
      type: Boolean,
    },
    isEmpty: {
      type: Boolean,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.field {
  text-align: left;
  margin: 0;
  min-height: 100%;
  position: relative;
  background-color: $color-input;
  border: 1px solid $color-input-border;
  border-radius: 0.25rem;
  font-size: 0;

  &:hover {
    border-color: $color-input-border-hover;
  }

  &:focus-within {
    border-color: $color-input-border-active;
  }

  label {
    pointer-events: none;
    color: $color-text-secondary;
    font-size: 0.5rem;
    font-weight: 500;
    vertical-align: top;
    position: absolute;
    left: 0.8rem;
    top: 0.5rem;
    transition: 0.2s;
  }

  &.view-mode.empty {
    label {
      font-size: 0.8rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .field-element {
    font-size: 0.8rem;

    .value {
      color: $color-text;
      min-height: 1rem;
      width: calc(100% - 1.6rem);
      padding: 1.2rem 0.8rem 0.5rem;
    }
  }
}

@media (max-width: 500px) {
  .field {
    label,
    .field-element {
      width: 100%;
    }
  }
}
</style>
