// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import store from './store';
import * as Sentry from '@sentry/vue';

import VaInput from './components/framework/va-form/field/va-input.vue';
import VaRadio from './components/framework/va-form/field/va-radio.vue';
import VaMedia from './components/framework/va-form/field/va-media.vue';
import VaColorpicker from './components/framework/va-form/field/va-colorpicker.vue';
import VaTextarea from './components/framework/va-form/field/va-textarea.vue';
import VaTextareaEditor from './components/framework/va-form/field/va-textarea-editor.vue';
import VaProductSelector from './components/framework/va-form/field/va-product-selector/va-product-selector.vue';
import VaSelect from './components/framework/va-form/field/va-select.vue';
import VaChoices from './components/framework/va-form/field/va-choices.vue';
import VaCheckbox from './components/framework/va-form/field/va-checkbox.vue';
import VaField from './components/framework/va-form/field/va-field.vue';
import VaToggle from '@/components/framework/va-toggle.vue';
import VaSwitch from './components/framework/va-form/field/va-switch.vue';
import VaTabBar from '@/components/framework/va-tabbar.vue';
import VaFields from './components/framework/va-form/va-fields.vue';
import VaFieldSet from './components/framework/va-form/va-fieldset.vue';
import VaContentLoader from './components/framework/va-content-loader.vue';
import VaSideDrawer from './components/framework/va-sidedrawer.vue';
import VaVid from './components/framework/va-vid.vue';
import VaDatepicker from '@/components/framework/va-datepicker/va-datepicker.vue';
import VaMessages from './components/framework/va-messages.vue';
import ColorSelector from './components/framework/utils/ColorSelector.vue';
import VaDate from './components/framework/va-form/field/va-date.vue';
import VaFormSlider from './components/framework/va-form/field/va-form-slider.vue';
import VaMultipleSelect from './components/framework/va-form/field/va-multiple-select.vue';
import VaMultipleLibraryMedia from './components/framework/va-form/field/va-multiple-library-media.vue';
import VaMultipleDiskMedia from './components/framework/va-form/field/va-multiple-disk-media.vue';
import VaItemMediaPicker from './components/framework/va-form/field/va-item-media-picker.vue';
import VaMetadataLayers from './components/framework/va-form/field/va-metadata-layers/va-metadata-layers.vue';
import VaDataTable from './components/framework/va-form/field/va-data-table/va-data-table.vue';
import VaItemDataPicker from './components/framework/va-form/field/va-item-data-picker/va-item-data-picker.vue';

import {
  userComputed,
  login,
  loginWithProvider,
} from '@/services/auth.service';

import VAConstants from './helpers/constants';
import TranslatePlugin from '@/plugins/translatePlugin';
import ErrorMessageHelper from './helpers/errorMessageHelper.js';
import PermissionsPlugin from '@/plugins/permissionsPlugin';
import Env from '@/utils/env';
import { waitForCondition } from '@/helpers/waitForCondition';

// Deal with preload errors
window.addEventListener('vite:preloadError', (event) => {
  event.preventDefault(); // Do not throw error
  window.location.reload();
});

const app = createApp(App);

Sentry.init({
  app,
  dsn: 'https://995553d95c771660bcb9dd012f09d202@o4508120804425728.ingest.de.sentry.io/4508120987402320',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    // Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/signageplayer\.app\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app.use(VAConstants);
app.use(VueAxios, axios);
app.use(TranslatePlugin, { store: store, getterName: 'userLanguage' });
app.use(PermissionsPlugin, {
  store: store,
  getterNameUser: 'userAuthorization',
  getterNameClient: 'clientAuthorization',
  isDebugModeActive: false,
});
app.use(Env);
app.use(router);
app.use(store);

// app.component('va-form', VaForm)
app.component('VaInput', VaInput);
app.component('VaRadio', VaRadio);
app.component('VaDate', VaDate);
app.component('VaMedia', VaMedia);
app.component('VaColorpicker', VaColorpicker);
app.component('VaTextarea', VaTextarea);
app.component('VaTextareaEditor', VaTextareaEditor);
app.component('VaProductSelector', VaProductSelector);
app.component('VaCheckbox', VaCheckbox);
app.component('VaSelect', VaSelect);
app.component('VaChoices', VaChoices);
app.component('VaField', VaField);
app.component('VaFields', VaFields);
app.component('VaFieldset', VaFieldSet);
app.component('VaContentLoader', VaContentLoader);
app.component('VaSidedrawer', VaSideDrawer);
app.component('VaVid', VaVid);
app.component('VaToggle', VaToggle);
app.component('VaSwitch', VaSwitch);
app.component('VaTabbar', VaTabBar);
app.component('VaDatepicker', VaDatepicker);
app.component('VaFormSlider', VaFormSlider);
app.component('VaMultipleSelect', VaMultipleSelect);
app.component('VaMessages', VaMessages);
app.component('VaMultipleLibraryMedia', VaMultipleLibraryMedia);
app.component('VaMultipleDiskMedia', VaMultipleDiskMedia);
app.component('VaItemMediaPicker', VaItemMediaPicker);
app.component('VaMetadataLayers', VaMetadataLayers);
app.component('VaDataTable', VaDataTable);
app.component('VaItemDataPicker', VaItemDataPicker);

app.component('ColorSelector', ColorSelector);

navigator.browserDetect = (function () {
  let an = navigator.appName,
    ua = navigator.userAgent,
    specialVersion,
    regex =
      // eslint-disable-next-line no-useless-escape
      /(edge|opera|chrome|safari|firefox|msie|trident|samsungbrowser)\/?\s*([\d\.]+)/gi;

  let matches,
    uaBrowsers = [];

  while ((matches = regex.exec(ua))) {
    // eslint-disable-next-line no-useless-escape
    specialVersion = ua.match(/version\/|rv:([\.\d]+)/i);
    if (specialVersion != null) {
      matches[2] = specialVersion[1];
    }
    uaBrowsers.push([matches[1], matches[2]]);
  }

  for (let x in uaBrowsers) {
    if (uaBrowsers[x][0] == 'Edge') {
      uaBrowsers = {
        browser: uaBrowsers[x][0],
        version: uaBrowsers[x][1],
        appName: an,
      };
    }
  }
  if (uaBrowsers.length == 0) {
    uaBrowsers = {
      browser: 'Unknown',
      version: navigator.appVersion,
      appName: an,
    };
  } else if (Array.isArray(uaBrowsers)) {
    uaBrowsers = {
      browser: uaBrowsers[0][0].replace(/msie|trident/i, 'Internet Explorer'),
      version: uaBrowsers[0][1],
      appName: an,
    };
  }

  return uaBrowsers;
})();

axios.interceptors.request.use(
  async function (config) {
    // Wait until the condition is fulfilled
    await waitForCondition(
      () => store.getters.isClientLoaded || config.switchingClient,
    );

    const authToken = userComputed.user.get()?.access_token;
    if (authToken) {
      if (config.url.startsWith(Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND'))) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
    } else {
      if (
        !~window.location.href.indexOf('passwordForgot') &&
        !~window.location.href.indexOf('userEmailConfirmation') &&
        !~window.location.href.indexOf('userEmailResetPassword')
      ) {
        login();
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  function (response) {
    if (response.data.success && !response.data.success.ok) {
      store.dispatch('addMessage', {
        message: {
          text: ErrorMessageHelper.renderErrorMessage(response),
          type: 'error',
        },
        time: 10000,
      });
    }
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      const provider = localStorage.getItem('provider');
      if (provider) {
        loginWithProvider(provider);
      } else {
        login();
      }
    } else if (
      error.response &&
      (error.response.status === 400 ||
        error.response.status === 403 ||
        error.response.status === 500)
    ) {
      store.dispatch('addMessage', {
        message: {
          text: ErrorMessageHelper.renderErrorMessage(error.response),
          type: 'error',
        },
        time: 10000,
      });
    }
    return Promise.reject(error);
  },
);

app.mount('#app-signage');

export { app };
