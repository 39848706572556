<template>
  <div class="month">
    <div class="week-numbers">
      <span
        v-for="number in Math.ceil(
          (lastDayOfMonth.getDate() + getDay(firstDayOfMonth) - 1) / 7,
        )"
        :key="`week-number-${number}`"
        class="week-number"
        :title="$translate('generic.week') + ' ' + getWeekNumber(number)"
      >
        {{ getWeekNumber(number) }}
      </span>
    </div>
    <div class="weeks">
      <!-- last days of previous month to fill first week-->
      <button
        v-for="day in getDay(firstDayOfMonth) - 1"
        :key="`prev-month-${day}`"
        :data-testid="`prev-month-${printPrevMonthDate(day)}`"
        :class="'day dark' + selectedDay(printPrevMonthDate(day), 2)"
        @click.prevent="$emit('select-day', printPrevMonthDate(day), -1)"
        @keyup.escape="$emit('close')"
      >
        {{ printPrevMonthDate(day) }}
      </button>
      <button
        v-for="day in lastDayOfMonth.getDate()"
        :key="day"
        :data-testid="`va-weeks--day-${day}`"
        :class="'day' + currentDay(day) + selectedDay(day)"
        @click.prevent="$emit('select-day', day)"
        @keyup.escape="$emit('close')"
      >
        {{ day }}
      </button>
      <!-- first days of next month to fill last week-->
      <button
        v-for="day in 7 - getDay(lastDayOfMonth)"
        :key="`next-month-${day}`"
        :data-testid="`next-month-${day}`"
        :class="'day dark' + selectedDay(day, 0)"
        @click.prevent="$emit('select-day', day, +1)"
        @keyup.escape="$emit('close')"
      >
        {{ day }}
      </button>
    </div>
  </div>
</template>
<script>
import {
  getWeek,
  getDay,
} from '@/components/framework/va-datepicker/datepicker.js';

export default {
  props: {
    displayYear: { type: Number, default: 2021 },
    displayMonth: { type: Number, default: 1 },
    selectedDate: {
      type: Date,
      default: new Date(),
    },
  },
  emits: ['select-day', 'close'],
  data() {
    return {
      firstDayOfMonth: new Date(),
      currentDate: new Date(),
      lastDayOfMonth: new Date(),
      prevMonthLastDay: new Date(),
    };
  },
  watch: {
    displayMonth() {
      this.updateVariables();
    },
  },
  created() {
    this.currentDate = new Date();
    this.updateVariables();
  },
  methods: {
    getDay,
    updateVariables() {
      this.firstDayOfMonth = new Date(this.displayYear, this.displayMonth - 1);
      this.lastDayOfMonth = new Date(
        this.firstDayOfMonth.getFullYear(),
        this.firstDayOfMonth.getMonth() + 1,
        0,
      );
      this.prevMonthLastDay = new Date(
        this.firstDayOfMonth.getFullYear(),
        this.firstDayOfMonth.getMonth(),
        0,
      );
    },
    printPrevMonthDate(day) {
      return (
        this.prevMonthLastDay.getDate() -
        getDay(this.firstDayOfMonth) +
        (day + 1)
      );
    },
    selectedDay(date, month = 1) {
      if (
        date === this.selectedDate.getDate() &&
        this.displayMonth === this.selectedDate.getMonth() + month &&
        this.displayYear === this.selectedDate.getFullYear()
      ) {
        return ' selected';
      }
      return '';
    },
    currentDay(date) {
      if (
        date === this.currentDate.getDate() &&
        this.displayYear === this.currentDate.getFullYear() &&
        this.displayMonth === this.currentDate.getMonth() + 1
      ) {
        return ' current';
      }
      return '';
    },
    getWeekNumber(weekInMonth) {
      weekInMonth--;
      let firstWeekOfTheMonth = getWeek(new Date(this.firstDayOfMonth));
      const lastWeekOfTheYear = getWeek(new Date(this.lastDayOfMonth));

      // If first week of the year are 52 or 53
      if (
        this.displayMonth === 1 &&
        weekInMonth !== 0 &&
        (firstWeekOfTheMonth === 52 || firstWeekOfTheMonth === 53)
      ) {
        return weekInMonth;
      }

      firstWeekOfTheMonth = firstWeekOfTheMonth + weekInMonth;

      // If the last week of the year are 1
      if (
        this.displayMonth === 12 &&
        lastWeekOfTheYear === 1 &&
        firstWeekOfTheMonth === 53
      ) {
        return 1;
      }

      return firstWeekOfTheMonth;
    },
  },
};
</script>

<style lang="scss" scoped>
$day-w-h: 1.875rem;

.month {
  display: grid;
  grid-gap: 0;
  grid-template-columns: 1.5rem auto;
}

.week-number {
  display: flex;
  align-items: center;
  height: $day-w-h;
  font-size: 0.75rem;
  cursor: help;
  padding: 1px;
}

.weeks {
  border-top: 1px solid $color-border;
  border-left: 1px solid $color-border;
  display: grid;
  grid-template-columns: repeat(7, $day-w-h);
  grid-gap: 0;
  grid-template-rows: $day-w-h;

  .day {
    font-family: inherit;
    border: none;
    border-right: 1px solid $color-border;
    border-bottom: 1px solid $color-border;
    font-size: 0.8rem;
    box-sizing: border-box;
    background: transparent;
    border-radius: 0;
    padding: 0;
    color: $color-text;
    cursor: pointer;

    &:hover {
      background-color: $color-bg-tertiary-hover;
    }

    &.dark {
      color: $color-border;
    }

    &.current {
      background: $color-bg-tertiary-active;
      color: $color-text;

      &:hover {
        background-color: $color-bg-tertiary-active-hover;
      }
    }

    &.selected {
      background: $color-fill-active;
      color: $color-text;

      &:hover {
        background: $color-fill-active;
      }
    }
  }
}
</style>
