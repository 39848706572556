<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8 1.99991H3.33333C2.97971 1.99991 2.64057 2.14038 2.39052 2.39043C2.14048 2.64048 2 2.97962 2 3.33324V12.6666C2 13.0202 2.14048 13.3593 2.39052 13.6094C2.64057 13.8594 2.97971 13.9999 3.33333 13.9999H12.6667C13.0203 13.9999 13.3594 13.8594 13.6095 13.6094C13.8595 13.3593 14 13.0202 14 12.6666V7.99991M12.25 1.74991C12.5152 1.48469 12.8749 1.33569 13.25 1.33569C13.6251 1.33569 13.9848 1.48469 14.25 1.74991C14.5152 2.01512 14.6642 2.37483 14.6642 2.74991C14.6642 3.12498 14.5152 3.48469 14.25 3.74991L8.24133 9.75924C8.08303 9.91741 7.88747 10.0332 7.67267 10.0959L5.75733 10.6559C5.69997 10.6726 5.63916 10.6736 5.58127 10.6588C5.52339 10.644 5.47055 10.6139 5.4283 10.5716C5.38604 10.5294 5.35593 10.4765 5.3411 10.4186C5.32627 10.3607 5.32727 10.2999 5.344 10.2426L5.904 8.32724C5.96702 8.11261 6.08302 7.91728 6.24133 7.75924L12.25 1.74991Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
