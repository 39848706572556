<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
      fill="currentColor"
    />
    <path
      d="M10.2198 4.83691C10.7848 4.83691 11.2376 5.3046 11.2193 5.86927L11.0554 10.9339C11.0379 11.4733 10.5956 11.9015 10.0559 11.9015H9.94887C9.40918 11.9015 8.96685 11.4733 8.94939 10.9339L8.78544 5.86927C8.76716 5.3046 9.21995 4.83691 9.78492 4.83691H10.2198ZM9.99989 15.1629C9.64525 15.1629 9.34032 15.037 9.08512 14.7851C8.83322 14.5332 8.70728 14.2282 8.70728 13.8703C8.70728 13.519 8.83322 13.219 9.08512 12.9704C9.34032 12.7185 9.64525 12.5926 9.99989 12.5926C10.3413 12.5926 10.6412 12.7185 10.8997 12.9704C11.1616 13.219 11.2925 13.519 11.2925 13.8703C11.2925 14.1089 11.2312 14.326 11.1086 14.5216C10.9892 14.7171 10.8318 14.8729 10.6363 14.9889C10.444 15.1049 10.2319 15.1629 9.99989 15.1629Z"
      fill="#181E24"
    />
  </svg>
</template>
