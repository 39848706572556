<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.79289 5.79289C6.18342 5.40237 6.81658 5.40237 7.20711 5.79289L10 8.58579L12.7929 5.79289C13.1834 5.40237 13.8166 5.40237 14.2071 5.79289C14.5976 6.18342 14.5976 6.81658 14.2071 7.20711L11.4142 10L14.2071 12.7929C14.5976 13.1834 14.5976 13.8166 14.2071 14.2071C13.8166 14.5976 13.1834 14.5976 12.7929 14.2071L10 11.4142L7.20711 14.2071C6.81658 14.5976 6.18342 14.5976 5.79289 14.2071C5.40237 13.8166 5.40237 13.1834 5.79289 12.7929L8.58579 10L5.79289 7.20711C5.40237 6.81658 5.40237 6.18342 5.79289 5.79289Z"
      fill="#181E24"
    />
  </svg>
</template>
